import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import Collapsible from '../../components/Collapsible';
import { InlineTextInput, InlineSelect, BasicButton, SubmitButton } from '../../components/Base';
import Utils from '../../helpers/utils';

import { submitOrder } from '../../actions/order';

export default function PaymentForm() {
  const PAYMENT_FORM_TEMPLATE = {
    payment_method_id: '',
    payment_method_name: '',
    amount: 0,
    fee_amount: 0,
    total: 0,
  };
  const history = useHistory();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { result: paymentMethods } = useSelector((state) => state.paymentMethod);
  const { submitResult: orderSubmitResult } = useSelector((state) => state.order);
  const checkout = useSelector((state) => state.checkout);
  const dispatch = useDispatch();

  const { warehouse, voucher = null } = checkout;

  const [paymentMethodOptions, setPaymentMethodOptions] = useState([...paymentMethods]);
  const [payments, setPayments] = useState([
    { ...PAYMENT_FORM_TEMPLATE, amount: checkout.grand_total },
  ]);
  const [feeTotal, setFeeTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [email, setEmail] = useState(authenticatedUser ? authenticatedUser.email : '');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  // Set Payment Method Option each time Warehouse or Voucher was changed
  useEffect(() => {
    console.log(paymentMethods);
    let filtered = [...paymentMethods];
    if (warehouse) {
      filtered = filtered.filter(
        (pm) =>
          Number(pm.payment_gateway) === 0 ||
          pm.payment_gateway_codes.filter((c) => c.code && c.warehouse_id === warehouse.id).length >
            0,
      );
    }
    if (voucher) filtered = filtered.filter((pm) => pm.id === voucher.payment_method_id);
    setPaymentMethodOptions([...filtered]);
  }, [paymentMethods, warehouse, voucher]);

  useEffect(() => {
    // Calculate Payment Fee
    const paymentFeeTotal = Math.ceil(payments.map((p) => p.fee_amount).reduce((a, b) => a + b, 0));
    setFeeTotal(paymentFeeTotal);

    // Calculate Grand Total
    const paymentGrandTotal = Math.ceil(payments.map((p) => p.total).reduce((a, b) => a + b, 0));
    setGrandTotal(paymentGrandTotal);
  }, [payments]);

  useEffect(() => {
    setPayments([{ ...PAYMENT_FORM_TEMPLATE, amount: checkout.grand_total }]);
  }, [checkout.grand_total]);

  useEffect(() => {
    if (isSubmittingForm && orderSubmitResult.data) {
      if (authenticatedUser) history.push(`/order-history/${orderSubmitResult.data.hash}`);
      else history.push(`/order/guest/${orderSubmitResult.data.hash}`);

      Swal.fire(
        'Submit Order Success',
        'Terima Kasih telah melakukan pemesanan melalui website kami. Segera selesaikan pembayaran agar kami dapat segera memproses pesanan Anda.',
        'success',
      );
    }
  }, [isSubmittingForm, orderSubmitResult]);

  //

  const onClickAddPaymentMethod = () => {
    const newPayments = [...payments];
    const paymentTemplate = { ...PAYMENT_FORM_TEMPLATE };

    // Calculate Rest Amount for Split Payment
    const currentPaymentTotal = payments.map((p) => p.amount).reduce((a, b) => a + b, 0);
    paymentTemplate.amount = Math.max(checkout.grand_total - currentPaymentTotal, 0);
    paymentTemplate.total = paymentTemplate.amount;

    newPayments.push({ ...paymentTemplate });
    setPayments(newPayments);
  };

  const onChangePaymentMethod = (e, index) => {
    const newPayments = [...payments];
    newPayments[index].payment_method_id = e.target.value;

    newPayments[index] = calculatePaymentTotal(newPayments[index]);
    setPayments(newPayments);
  };

  const onChangePaymentAmount = (e, index) => {
    const newPayments = [...payments];
    newPayments[index].amount = parseFloat(e.target.value);

    newPayments[index] = calculatePaymentTotal(newPayments[index]);
    setPayments(newPayments);
  };

  const calculatePaymentTotal = (payment) => {
    const paymentMethod = paymentMethods.find((s) => s.id === Number(payment.payment_method_id));
    if (paymentMethod) {
      const fee = parseFloat(paymentMethod.fee_amount);
      payment.payment_method_name = paymentMethod.name;
      payment.fee_amount =
        paymentMethod.fee_type === 'Percentage' ? (fee / 100) * payment.amount : fee;
      payment.total = payment.amount + payment.fee_amount;
    }
    return { ...payment };
  };

  // Split Payment, Calculate rest amount for next Payment Amount
  const calculateSplitPaymentRestAmount = (e, index) => {
    // Reset all next Payment Amount to 0
    const newPayments = payments.map((np, npIndex) => ({
      ...np,
      amount: npIndex > index ? 0 : np.amount,
      total: (npIndex > index ? 0 : np.amount) + np.fee_amount,
    }));
    const currentPaymentTotal = newPayments.map((p) => p.amount).reduce((a, b) => a + b, 0);

    // Set Next Payment amount to Rest Amount
    if (newPayments[index + 1]) {
      newPayments[index + 1].amount = Math.max(checkout.grand_total - currentPaymentTotal, 0);
      newPayments[index + 1] = calculatePaymentTotal(newPayments[index + 1]);
    }

    setPayments(newPayments);
  };

  //

  const onClickSubmit = () => {
    // Check Shipping Method
    // if (!checkout.shipping_method_id || checkout.shipping_method_id === '') {
    // Swal.fire('Submit Failed', 'Silahkan pilih Metode Pengiriman terlebih dahulu', 'error');
    // return;
    // }

    // Check Payment Total
    const totalPaymentAmount = payments
      .map((p) => p.total - p.fee_amount)
      .reduce((a, b) => a + b, 0);
    if (checkout.grand_total !== totalPaymentAmount) {
      Swal.fire(
        'Submit Failed',
        'Total Jumlah Pembayaran harus sama dengan Total Tagihan',
        'error',
      );
      return;
    }

    // Check Email
    if (!email || email.trim() === '') {
      Swal.fire('Submit Failed', 'Kolom Email harus diisi', 'error');
      return;
    }

    setIsSubmittingForm(true);

    const orderData = {
      ...checkout,
      payment_fee: feeTotal,
      grand_total: grandTotal,
      details: checkout.details.map((detail) => ({
        product_id: detail.product.id,
        product_variant_id: detail.variant ? detail.variant.id : null,
        price: parseFloat(detail.product.price),
        quantity: detail.quantity,
        weight: parseFloat(detail.product.weight),
        total_weight: parseFloat(detail.product.weight) * detail.quantity,
      })),
      payments: payments.filter((p) => p.amount > 0),
      email,
    };
    dispatch(submitOrder(orderData))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  if (checkout.isEditingUserInformation) return null;
  return (
    <Collapsible title="Metode Pembayaran">
      <div className="relative w-full mb-5">
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
          <div className="uppercase text-gray-700 text-xs font-bold">Total Tagihan</div>
          <div className="col-span-2 xl:col-span-3">
            {`Rp ${Utils.numberFormat(checkout.grand_total, 2)}`}
          </div>
        </div>
      </div>

      {payments.map((payment, index) => (
        <div key={index} className="relative w-full mb-5">
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4">
            <div className="uppercase text-gray-700 text-xs font-bold">
              {`Pembayaran 0${index + 1}`}
            </div>
            <div className="-mt-2 col-span-2 xl:col-span-3">
              <InlineSelect
                title="Metode Pembayaran"
                value={payment.payment_method_id}
                options={paymentMethodOptions.map((method) => (
                  <option key={method.id} value={method.id}>
                    {`${method.name} (Biaya Layanan: ${
                      method.fee_type === 'Percentage' ? '' : 'Rp '
                    }${Utils.numberFormat(method.fee_amount, 2)}${
                      method.fee_type === 'Percentage' ? '%' : ''
                    })`}
                  </option>
                ))}
                onChange={(e) => onChangePaymentMethod(e, index)}
              />

              <InlineTextInput
                type="number"
                title="Jumlah"
                value={`${payment.amount}`}
                onChange={(e) => onChangePaymentAmount(e, index)}
                onBlur={(e) => calculateSplitPaymentRestAmount(e, index)}
              />
              {payment.fee_amount > 0 && (
                <div className="relative w-full -mt-4 mb-5">
                  <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
                    <div></div>
                    <div className="col-span-2 xl:col-span-3 text-xs italic">
                      {`+ Biaya Layanan Rp ${Utils.numberFormat(payment.fee_amount, 0)}`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}

      {payments.length < 3 && (
        <BasicButton
          additionalClass="w-full -mt-6 mb-8"
          text="Tambah Metode Pembayaran (Split Pembayaran)"
          textClass="text-white text-xs"
          onClick={onClickAddPaymentMethod}
        />
      )}

      <div className="relative w-full mb-5">
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
          <div className="uppercase text-gray-700 text-xs font-bold">Total Pembayaran</div>
          <div className="col-span-2 xl:col-span-3 font-bold">
            {`Rp ${Utils.numberFormat(grandTotal, 2)}`}
          </div>
        </div>
      </div>

      <div>
        <InlineTextInput
          type="email"
          title="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className="relative w-full -mt-4 mb-5">
          <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4">
            <div></div>
            <div className="col-span-2 xl:col-span-3 text-xs italic">
              &nbsp; Alamat Email akan digunakan untuk mengirimkan notifikasi pembayaran Anda
            </div>
          </div>
        </div>
      </div>

      <SubmitButton
        additionalClass="w-full mt-12"
        type="button"
        text="Lanjutkan Pembayaran"
        textClass="text-white text-xs"
        isLoading={isSubmittingForm}
        onClick={onClickSubmit}
      />
    </Collapsible>
  );
}
