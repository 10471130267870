import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import WishlistCard from './WishlistCard';
import { deleteWishlist } from '../../../actions/wishlist';

export default function Wishlist() {
  const { result: wishlists } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();

  const onClickDelete = (wishlist) => dispatch(deleteWishlist(wishlist.id));

  return (
    <>
      <div className="relative mt-3">
        {/* Header */}
        <div className="flex flex-wrap items-center">
          <div className="flex-1">
            <h3 className="font-bold text-lg">Wishlist</h3>
          </div>
        </div>

        <div className="mt-3">
          {wishlists.map((wishlist) => (
            <WishlistCard key={wishlist.id} wishlist={wishlist} onClickDelete={onClickDelete} />
          ))}
        </div>
      </div>
    </>
  );
}
