import React from 'react';
import { useSelector } from 'react-redux';

import Collapsible from '../../../../components/Collapsible';
import Utils from '../../../../helpers/utils';

export default function ProductTable() {
  const cellClass = 'px-2 py-2 border-b text-sm';
  const { detailResult: order } = useSelector((state) => state.order);

  return (
    <Collapsible title="Detail Produk">
      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              SKU
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Nama Produk
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Harga
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Quantity
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Total
            </th>
          </tr>
        </thead>

        <tbody>
          {order.order_details.map((detail) => (
            <tr key={detail.id}>
              <td className={cellClass}>{detail.product_sku}</td>
              <td className={cellClass}>
                {detail.product_name}
                {detail.product_variant_name ? `<br />${detail.product_variant_name}` : ''}
              </td>
              <td className={cellClass}>{Utils.numberFormat(detail.price, 0)}</td>
              <td className={cellClass}>{Utils.numberFormat(detail.quantity, 0)}</td>
              <td className={`${cellClass} text-right`}>
                {Utils.numberFormat(parseFloat(detail.price) * parseFloat(detail.quantity), 0)}
              </td>
            </tr>
          ))}
        </tbody>

        <tfoot>
          <tr>
            <th colSpan="4" className={`${cellClass} font-normal text-right`}>
              Sub-Total
            </th>
            <th className={`${cellClass} font-normal text-right`}>
              {Utils.numberFormat(order.total, 0)}
            </th>
          </tr>
          <tr>
            <th colSpan="4" className={`${cellClass} font-normal text-right`}>
              Voucher
            </th>
            <th className={`${cellClass} font-normal text-right`}>
              - {Utils.numberFormat(order.voucher_discount, 0)}
            </th>
          </tr>
          <tr>
            <th colSpan="4" className={`${cellClass} font-normal text-right`}>
              Shipping Fee
            </th>
            <th className={`${cellClass} font-normal text-right`}>
              {Utils.numberFormat(order.total_shipping_fee, 0)}
            </th>
          </tr>
          <tr>
            <th colSpan="4" className={`${cellClass} font-normal text-right`}>
              Insurance Fee
            </th>
            <th className={`${cellClass} font-normal text-right`}>
              {Utils.numberFormat(order.total_insurance_fee, 0)}
            </th>
          </tr>
          <tr>
            <th colSpan="4" className={`${cellClass} font-normal text-right`}>
              Payment Fee
            </th>
            <th className={`${cellClass} font-normal text-right`}>
              {Utils.numberFormat(order.payment_fee, 0)}
            </th>
          </tr>

          <tr>
            <th colSpan="4" className={`${cellClass} text-right`}>
              GRAND TOTAL
            </th>
            <th className={`${cellClass} text-right`}>
              {Utils.numberFormat(order.grand_total, 0)}
            </th>
          </tr>
        </tfoot>
      </table>
    </Collapsible>
  );
}
