import React from 'react';
import { Link } from 'react-router-dom';
import { StarIcon } from '@heroicons/react/solid';

import { APP_URL } from '../../constants/env';
import Utils from '../../helpers/utils';

function ProductCard({ product }) {
  const discount = Number(product.original_price) - Number(product.price);
  const discountPercentage =
    Number(product.original_price) > 0 ? (discount / Number(product.original_price)) * 100 : 0;

  return (
    <div className="w-full relative shadow-lg rounded-xl overflow-hidden">
      <Link to={`/product/${product.url_slug}`}>
        <div>
          <img
            className="object-cover object-center w-full h-full aspect-square"
            src={`${APP_URL}/${product.image_1}`}
            alt={product.name}
          />
          <div className="px-4 py-4">
            <div className="flex items-start h-12 overflow-y-hidden">
              <h2 className="flex-1 leading-2">{product.name}</h2>
              {product.star > 0 && (
                <div className="flex items-center">
                  <StarIcon className="w-6 h-6 text-yellow-400" />
                  <span>{Utils.numberFormat(product.star, 1)}</span>
                </div>
              )}
            </div>
            <div className="pt-1 h-12">
              {discountPercentage > 0 && (
                <div className="text-sm">
                  <span className="text-gray-300 line-through">
                    Rp {Utils.numberFormat(product.original_price)}
                  </span>
                  <span className="text-red-400 font-bold">
                    {` ${Utils.numberFormat(discountPercentage)}%`}
                  </span>
                </div>
              )}
              <div className="-mt-1 text-lg font-bold">
                Rp {Utils.numberFormat(product.price, 0)}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* <div className="px-4 h-10">
        {product.star > 0 && <RatingStar editable={false} defaultValue={product.star} />}
      </div> */}

      {Number(product.total_online_stock) <= 0 && (
        <div className="absolute inset-0 rounded-xl bg-gray-800 bg-opacity-50">
          <div className="flex w-full h-full items-center justify-center">
            <div className="flex items-center w-20 h-20 rounded-full bg-purple-600">
              <span className="text-white text-center font-medium leading-none">Stock Habis</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(ProductCard);
