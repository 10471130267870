import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { Modal, InlineTextInput, BasicButton, SubmitButton } from '../../../../components/Base';
import { submitPaymentConfirmation, fetchOrderDetail } from '../../../../actions/order';

function PaymentConfirmationForm(props, ref) {
  const { register, errors, handleSubmit, setError, clearErrors } = useForm();

  const { detailResult: order } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [initialPayment, setInitialPayment] = useState(null);
  const [image, setImage] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  useImperativeHandle(ref, () => ({ showForm }));

  const showForm = (data) => {
    setInitialPayment(data);
    setIsVisible(true);
  };
  const closeForm = () => {
    setIsVisible(false);
    setInitialPayment(null);
    setIsSubmittingForm(false);
  };

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const uploadedFile = e.target.files[0];

      if (uploadedFile.type.indexOf('image') === -1) {
        setError('payment_receipt', {
          type: 'manual',
          message: 'Image must be an image (jpg, png, gif, svg, or webp)',
        });
        return;
      }

      if (uploadedFile.size > 1000000) {
        setError('payment_receipt', {
          type: 'manual',
          message: "Image size can't be larger than 1 MB",
        });
        return;
      }

      clearErrors('payment_receipt');
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    const formData = new FormData();
    formData.append('order_payment_id', initialPayment.id);
    formData.append('payment_receipt', data.payment_receipt[0]);

    dispatch(submitPaymentConfirmation(formData))
      .then(() => {
        dispatch(fetchOrderDetail(order.hash));
        closeForm();
      })
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Konfirmasi Pembayaran
            </Dialog.Title>
            <div className="mt-5">
              <div>Silahkan upload Bukti Pembayaran untuk mengkonfirmasi Pembayaran Anda</div>

              <form id="confirmationForm" onSubmit={handleSubmit(onSubmitForm)}>
                <img src={image} alt="" className="w-48 mb-2" />
                <InlineTextInput
                  type="file"
                  accept="image/*"
                  title="Bukti Pembayaran"
                  inputRef={register({ required: true })}
                  name="payment_receipt"
                  onChange={onImageChange}
                  errorMessage={
                    errors.payment_receipt &&
                    (errors.payment_receipt.type === 'manual'
                      ? errors.payment_receipt.message
                      : 'Kolom Bukti Pembayaran harus diisi')
                  }
                />
                <div className="-mt-3 mb-4 text-right text-xs">Image size max 1 MB &emsp;</div>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="confirmationForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(PaymentConfirmationForm);
