import { combineReducers } from 'redux';

import authenticatedUser from './authenticatedUser';
import auth from './auth';
import profile from './profile';
import rajaongkir from './rajaongkir';

import page from './page';
import slider from './slider';
import paymentMethod from './paymentMethod';
import shippingMethod from './shippingMethod';
import warehouse from './warehouse';
import settings from './settings';

import productCategory from './productCategory';
import product from './product';

import userAddress from './userAddress';
import wishlist from './wishlist';

import cart from './cart';
import checkout from './checkout';
import order from './order';

import review from './review';

export default combineReducers({
  authenticatedUser,
  auth,
  profile,
  rajaongkir,

  page,
  slider,
  paymentMethod,
  shippingMethod,
  warehouse,
  settings,

  productCategory,
  product,

  userAddress,
  wishlist,

  cart,
  checkout,
  order,

  review,
});
