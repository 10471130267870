import React from 'react';

export default function BasicButton({
  text,
  isLoading = false,
  onClick = () => {},
  color = 'gray',
  paddingClass = 'px-5 py-3',
  textClass = 'text-sm',
  additionalClass = '',
  ...props
}) {
  const isLoadingClass = isLoading ? 'cursor-not-allowed' : '';

  const onButtonClick = () => {
    if (isLoading) return;
    onClick();
  };

  return (
    <>
      <div className="relative inline">
        <button
          className={`inline-flex justify-center items-center rounded mb-1
            bg-transparent border border-solid
            border-${color}-400 hover:border-${color}-500 active:border-${color}-600
            text-${color}-400 hover:text-${color}-500 active:text-${color}-600 font-bold uppercase 
            shadow hover:shadow-lg outline-none focus:outline-none
            ease-linear transition-all duration-150
            ${paddingClass} ${textClass} ${isLoadingClass} ${additionalClass}`}
          {...props}
          onClick={onButtonClick}
          disabled={isLoading}>
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
          {text}
        </button>
      </div>
    </>
  );
}
