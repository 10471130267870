import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';

import Collapsible from '../../../components/Collapsible';
import { InlineTextInput, SubmitButton } from '../../../components/Base';

import { changePassword } from '../../../actions/profile';

export default function PasswordForm() {
  const { register, errors, handleSubmit } = useForm();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    if (data.new_password.trim() !== data.confirm_new_password.trim()) {
      Swal.fire('Submit Failed', 'Konfirmasi Password Baru tidak sesuai', 'error');
      return;
    }

    setIsSubmittingForm(true);

    dispatch(changePassword(data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Collapsible title="My Password">
      <form id="passwordForm" onSubmit={handleSubmit(onSubmitForm)}>
        <InlineTextInput
          type="password"
          title="Current Password"
          inputRef={register({ required: true })}
          name="current_password"
          errorMessage={errors.current_password && 'Kolom Current Password harus diisi'}
        />

        <InlineTextInput
          type="password"
          title="New Password"
          inputRef={register({ required: true })}
          name="new_password"
          errorMessage={errors.new_password && 'Kolom New Password harus diisi'}
        />

        <InlineTextInput
          type="password"
          title="Confirm New Password"
          inputRef={register({ required: true })}
          name="confirm_new_password"
          errorMessage={errors.confirm_new_password && 'Kolom Confirm New Password harus diisi'}
        />

        <div className="mt-5">
          <SubmitButton
            type="submit"
            form="passwordForm"
            text=" &emsp; Change Password &emsp; "
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
        </div>
      </form>
    </Collapsible>
  );
}
