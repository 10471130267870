import React, { useState } from 'react';
import { LockClosedIcon } from '@heroicons/react/outline';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { submitSignIn } from '../../../actions/auth';

export default function Login() {
  const history = useHistory();
  const { register, handleSubmit } = useForm();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    if (isSubmittingForm) return true;

    setIsSubmittingForm(true);
    dispatch(submitSignIn(data))
      .then(() => history.push('/'))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <>
      <div className="flex justify-center px-4">
        <div className="max-w-md w-full mb-40 pt-10">
          <div>
            <h2 className="text-center text-3xl font-extrabold text-gray-900">
              Sign In to Your Account
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {"Don't have an Account? "}
              <Link to="/sign-up" className="font-medium text-blue-500 hover:text-blue-700">
                Create Account Here
              </Link>
            </p>
          </div>

          <form className="mt-8" onSubmit={handleSubmit(onSubmitForm)}>
            <div className="rounded-md shadow-sm">
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone No
                </label>
                <input
                  id="phone"
                  ref={register({ required: true })}
                  name="phone"
                  type="phone"
                  autoComplete="phone"
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10"
                  placeholder="Phone No"
                  required
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  ref={register({ required: true })}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:z-10"
                  placeholder="Password"
                  required
                />
              </div>
            </div>

            {/* <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <a href="#" className="font-medium text-purple-600 hover:text-purple-500">
                  Forgot your password?
                </a>
              </div>
            </div> */}

            <div className="mt-8">
              <button
                type="submit"
                className="group relative w-full flex justify-center py-3 px-4 border border-transparent font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon
                    className="h-5 w-5 text-purple-500 group-hover:text-purple-400"
                    aria-hidden="true"
                  />
                </span>
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
