import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import Collapsible from '../../../components/Collapsible';
import { InlineTextInput, InlineSelect, SubmitButton } from '../../../components/Base';
import { USER_GENDER } from '../../../constants/data';

import { changeProfile } from '../../../actions/profile';

export default function ProfileForm() {
  const { register, errors, handleSubmit } = useForm();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(changeProfile(data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <Collapsible title="My Profile">
      <form id="profileForm" onSubmit={handleSubmit(onSubmitForm)}>
        <InlineTextInput
          type="text"
          title="First Name"
          inputRef={register({ required: true })}
          name="first_name"
          errorMessage={errors.first_name && 'Kolom First Name harus diisi'}
          defaultValue={authenticatedUser.first_name}
        />

        <InlineTextInput
          type="text"
          title="Last Name"
          inputRef={register()}
          name="last_name"
          defaultValue={authenticatedUser.last_name}
        />

        <InlineSelect
          title="Gender"
          inputRef={register()}
          name="gender"
          options={Object.values(USER_GENDER).map((gender) => (
            <option key={gender.value} value={gender.value}>
              {gender.label}
            </option>
          ))}
          defaultValue={authenticatedUser.gender}
        />

        <div className="mt-5">
          <SubmitButton
            type="submit"
            form="profileForm"
            text=" &emsp; Update Profile &emsp; "
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
        </div>
      </form>
    </Collapsible>
  );
}
