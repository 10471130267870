import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import RatingStar from '../../../../components/RatingStar';
import { SubmitButton } from '../../../../components/Base';

import { submitReview } from '../../../../actions/review';

export default function ReviewForm({ orderDetail }) {
  const { register, handleSubmit } = useForm();

  const dispatch = useDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(submitReview(data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  return (
    <form
      id={`reviewForm-${orderDetail.id}`}
      onSubmit={handleSubmit(onSubmitForm)}
      className="mt-3">
      <input
        ref={register({ required: true })}
        type="hidden"
        name="product_id"
        value={orderDetail.product_id}
      />
      <input
        ref={register({ required: true })}
        type="hidden"
        name="order_id"
        value={orderDetail.order_id}
      />
      <input
        ref={register({ required: true })}
        type="hidden"
        name="order_detail_id"
        value={orderDetail.id}
      />

      <RatingStar inputRef={register({ required: true })} name="star" />

      <textarea
        ref={register()}
        className="w-full border rounded mt-1 px-2 py-2"
        placeholder="Tuliskan bagaimana pendapatmu mengenai Produk yang kamu beli"
        name="review"
        rows={2}></textarea>

      <SubmitButton
        type="submit"
        form={`reviewForm-${orderDetail.id}`}
        text="Simpan Review"
        textClass="text-white text-xs"
        isLoading={isSubmittingForm}
      />
    </form>
  );
}
