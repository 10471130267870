import React, { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/outline';

export default function Collapsible({ title = '', children }) {
  const [isVisible, setIsVisible] = useState(true);
  const onTitleClick = () => setIsVisible(!isVisible);

  return (
    <div className="relative">
      <div
        className="flex px-4 py-2 items-center bg-gray-100 cursor-pointer"
        onClick={onTitleClick}>
        <h3 className="flex-1 font-bold">{title}</h3>
        <ChevronRightIcon
          className={`h-5 w-5 transition transition-all duration-500 ease-in-out
          transform ${isVisible ? 'rotate-90' : 'rotate-0'}`}
        />
      </div>
      <div
        className={`px-4 transition transition-all duration-500 ease-in-out
        overflow-hidden overflow-x-scroll ${isVisible ? 'py-4 max-h-auto' : 'py-0 max-h-0'}`}>
        {children}
      </div>
    </div>
  );
}
