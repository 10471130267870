import Swal from 'sweetalert2';
// import moment from 'moment';
import {
  ADD_CART_TO_CHECKOUT,
  SET_IS_EDITING_USER_INFORMATION,
  SET_USER_INFORMATION,
  SET_SHIPPING_TYPE,
  SET_SHIPPING_WAREHOUSE,
  SET_SHIPPING_METHOD,
  SET_SHIPPING_METHOD_TYPE,
  APPLY_VOUCHER,
} from './_types';
import {
  CART_STORAGE_KEY,
  API_URL,
  API_RESPONSE_STATUS,
  API_RESPONSE_MESSAGE,
  API_RESPONSE,
} from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';
import Utils from '../helpers/utils';

export const addCartToCheckout = () => {
  return (dispatch) => {
    const cartsString = localStorage.getItem(CART_STORAGE_KEY);
    const carts = cartsString ? JSON.parse(cartsString) : [];

    return dispatch({ type: ADD_CART_TO_CHECKOUT, result: [...carts] });
  };
};

export const setIsEditingUserInformation = (isEditing) => {
  return (dispatch) => {
    return dispatch({ type: SET_IS_EDITING_USER_INFORMATION, result: isEditing });
  };
};

export const setUserInformation = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_USER_INFORMATION, result: { ...data } });
  };
};

export const setShippingType = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_SHIPPING_TYPE, result: data });
  };
};

export const setShippingWarehouse = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_SHIPPING_WAREHOUSE, result: data });
  };
};

export const setShippingMethod = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_SHIPPING_METHOD, result: data });
  };
};

export const setShippingMethodType = (data) => {
  return (dispatch) => {
    return dispatch({ type: SET_SHIPPING_METHOD_TYPE, result: data });
  };
};

/**
 * @param data      voucher_code
 */
export const applyVoucher = (data) => {
  return (dispatch, getState) => {
    return fetch(`${API_URL}/order/apply-voucher`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        const { total } = getState().checkout;

        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            if (total < parseFloat(response.data.min_order)) {
              Swal.fire(
                'Submit Failed',
                `Kode Voucher hanya dapat digunakan dengan min. pembelanjaan ${Utils.numberFormat(
                  response.data.min_order,
                )}`,
                'error',
              );
              return Promise.reject(response[API_RESPONSE_MESSAGE]);
            } else {
              Swal.fire('Submit Success', 'Kode Voucher telah berhasil digunakan', 'success');
              dispatch({ type: APPLY_VOUCHER, result: response.data });
            }
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};
