import {
  SUBMIT_SIGN_IN,
  SUBMIT_SIGN_UP,
  // REQUEST_OTP,
  VALIDATE_OTP,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  submitResult: {},
  validateOtpResult: {},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_SIGN_IN:
      return {
        ...state,
        submitResult: action.result,
      };

    case SUBMIT_SIGN_UP:
      return {
        ...state,
        submitResult: action.result,
      };

    case VALIDATE_OTP:
      return {
        ...state,
        validateOtpResult: action.result,
      };

    case RESET_STORE:
      return {
        ...state,
        submitResult: {},
      };

    default:
      return state;
  }
}
