import React from 'react';
import { Link } from 'react-router-dom';

import { APP_URL } from '../../../constants/env';
import Utils from '../../../helpers/utils';
import { DeleteButton } from '../../../components/Base';

function WishlistCard({ wishlist, onClickDelete = () => {} }) {
  const { product, product_variant: variant, price } = wishlist;

  const mOnClickDelete = () => {
    onClickDelete(wishlist);
  };

  return (
    <div className="flex flex-wrap items-center w-full shadow-lg rounded-xl p-4 mb-4">
      <img
        className="object-cover object-center w-32 h-32 rounded"
        src={`${APP_URL}/${product.image_1}`}
        alt={product.name}
      />

      <div className="flex-1 px-4">
        <Link to={`/product/${product.url_slug}`}>
          <h2 className="leading-none text-lg font-bold">{product.name}</h2>
        </Link>
        {variant && <p className="text-gray-400 text-sm">{`Variasi: ${variant.name}`}</p>}

        <p className="mt-1">Rp {Utils.numberFormat(price, 0)}</p>
      </div>

      <div className="pr-4">
        <DeleteButton
          text="Hapus"
          paddingClass="py-2 px-3"
          textClass="text-white text-xs"
          onClick={mOnClickDelete}
        />
      </div>
    </div>
  );
}

export default React.memo(WishlistCard);
