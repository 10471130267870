import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import $ from 'jquery';

import { SubmitButton } from '../../../components/Base';
import { API_URL, DATATABLES_DEFAULT_CONFIG } from '../../../constants/env';
import Utils from '../../../helpers/utils';

export default function OrderHistory() {
  const history = useHistory();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { refresh } = useSelector((state) => state.order);

  useEffect(() => {
    initializeDataTables();
  }, [refresh]);

  const initializeDataTables = () => {
    $('#orderDataTable').DataTable().destroy();
    $('#orderDataTable').DataTable({
      ...DATATABLES_DEFAULT_CONFIG,
      dom: `<''
        <'dt-table mt-3'
          <''tr>
        >
        <'sm:flex mt-3 justify-center'
          <'sm:flex-1 text-center sm:text-left'i>
          <'sm:flex-1 text-center sm:text-right'p>
        >
      >`,
      ajax: {
        url: `${API_URL}/order?source=datatables`,
        dataSrc: 'data',
        beforeSend: (xhr) =>
          xhr.setRequestHeader('Authorization', `Bearer ${authenticatedUser.token}`),
      },
      columns: [
        { data: 'order_no' },
        { data: 'order_time' },
        {
          data: 'grand_total',
          render: (data) => Utils.numberFormat(data, 2),
        },
        {
          data: null,
          searchable: false,
          sortable: false,
          render: (data, type, row) => Utils.generateOrderStatus(row),
        },
        {
          data: 'hash',
          searchable: false,
          sortable: false,
          createdCell: (cell, cellData) =>
            ReactDOM.render(
              <div>
                <SubmitButton
                  color="green"
                  textClass="text-white text-xs"
                  paddingClass="px-3 py-2 mr-1"
                  text="View"
                  onClick={() => onPressView(cellData)}
                />
              </div>,
              cell,
            ),
        },
      ],
      order: [[0, 'desc']],
    });
  };

  const onPressView = (hash) => history.push(`order-history/${hash}`);

  return (
    <>
      <div className="relative mt-3">
        {/* Header */}
        <div className="flex flex-wrap items-center">
          <div className="flex-1">
            <h3 className="font-bold text-lg">Order History</h3>
          </div>
        </div>

        {/* Table Content */}
        <div className="mt-3 block w-full overflow-x-auto">
          {/* Projects table */}
          <table id="orderDataTable" className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Order No
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Order Time
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Total
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left">
                  Status
                </th>
                <th className="px-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-semibold text-left w-20"></th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
    </>
  );
}
