import React, { useRef, useEffect } from 'react';
import { PlusCircleIcon } from '@heroicons/react/outline';
import { useSelector, useDispatch } from 'react-redux';

import Collapsible from '../../components/Collapsible';
import UserAddressCreate from '../Profile/UserAddress/Create';

import { fetchUserAddress } from '../../actions/userAddress';
import { setUserInformation, setIsEditingUserInformation } from '../../actions/checkout';

export default function UserAddressForm() {
  const userAddressFormRef = useRef();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { result: userAddresses } = useSelector((state) => state.userAddress);
  const checkout = useSelector((state) => state.checkout);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserAddress());
  }, []);

  const onClickAddUserAddress = () => userAddressFormRef.current.showForm();

  const onSelectUserAddress = (address) => {
    const userData = {
      first_name: authenticatedUser.first_name,
      last_name: authenticatedUser.last_name,
      phone: authenticatedUser.phone,

      user_address_id: address.id,
      shipping_first_name: address.first_name,
      shipping_last_name: address.last_name,
      shipping_phone: address.phone,
      street_address: address.street_address,
      district_id: address.district_id,
      district: address.district,
      city_id: address.city_id,
      city: address.city,
      province_id: address.province_id,
      province: address.province,
      zip_code: address.zip_code,
    };
    dispatch(setUserInformation(userData));
    dispatch(setIsEditingUserInformation(false));
  };

  return (
    <Collapsible title="Data Diri dan Alamat">
      <UserAddressCreate ref={userAddressFormRef} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {userAddresses.map((address) => (
          <div
            key={address.id}
            className={`rounded-lg p-3 cursor-pointer
                ${
                  checkout.user_address_id === address.id ? 'border-2 border-purple-800' : 'border'
                }`}
            onClick={() => onSelectUserAddress(address)}>
            <h4 className="-mt-1 font-bold">{address.address_name}</h4>
            <div className="text-sm">
              {`${address.street_address}\n${address.district}, ${address.city}\n
                ${address.province} ${address.zip_code}`}
            </div>
            <div className="text-sm">
              {`${address.first_name} ${address.last_name} (Ph: ${address.phone})`}
            </div>
          </div>
        ))}

        <div
          className="flex flex-col items-center justify-center border rounded-lg p-4 cursor-pointer"
          onClick={onClickAddUserAddress}>
          <PlusCircleIcon className="h-10 w-10" />
          <div>Tambah Alamat Baru</div>
        </div>
      </div>
    </Collapsible>
  );
}
