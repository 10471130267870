import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import CartCard from './CartCard';
import FeaturedProduct from '../Product/FeaturedProduct';
import Utils from '../../helpers/utils';

export default function Cart() {
  const history = useHistory();

  const [total, setTotal] = useState(0);

  const { result: carts } = useSelector((state) => state.cart);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const mTotal = carts.map((c) => c.product.price * c.quantity).reduce((a, b) => a + b, 0);
    setTotal(mTotal);
  }, [carts]);

  const onClickCheckout = () => {
    history.push({ pathname: '/checkout' });
  };

  return (
    <>
      <h2 className="text-2xl font-bold">Shopping Cart</h2>
      <section className="flex flex-wrap mt-5 pb-10">
        <div className="w-full lg:w-2/3">
          {carts.map((cart, index) => (
            <div key={cart.time} className="mb-3">
              <CartCard cart={cart} index={index} />
            </div>
          ))}

          {carts.length === 0 && (
            <span className="text-lg text-center">Belum ada Produk apapun di dalam Cart</span>
          )}

          <div className="mt-6">
            <Link to="/" className="font-semibold text-blue-500 transition-all hover:underline">
              Lanjutkan Berbelanja
            </Link>
          </div>
        </div>

        <div className="w-full lg:w-1/3 lg:pl-8 mt-6 lg:mt-0">
          {carts.length > 0 && (
            <>
              {/* Cart Summary */}
              <div className="p-4 w-full rounded-xl border border-gray-200">
                <div className="flex items-center py-1">
                  <div>Sub Total</div>
                  <div className="ml-auto">Rp {Utils.numberFormat(total, 2)}</div>
                </div>
                <div className="flex items-center py-1">
                  <div>Voucher</div>
                  <div className="ml-auto">-</div>
                </div>
                <div className="flex items-center py-1">
                  <div>Shipping Fee</div>
                  <div className="ml-auto">-</div>
                </div>
                <div className="flex items-center mt-1 py-2 border-t font-bold">
                  <div>Grand Total</div>
                  <div className="ml-auto">Rp {Utils.numberFormat(total, 2)}</div>
                </div>
                <button
                  className="w-full mt-2 py-2 px-6 focus:outline-none rounded transition-all
                    bg-purple-600 hover:bg-purple-700 text-gray-100 hover:text-white font-medium"
                  onClick={onClickCheckout}>
                  Checkout
                </button>
              </div>
            </>
          )}
        </div>
      </section>

      <div className="mt-10">
        <FeaturedProduct />
      </div>
    </>
  );
}
