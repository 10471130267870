import { SET_AUTHENTICATED_USER, EMPTY_AUTHENTICATED_USER, RESET_STORE } from '../actions/_types';
import { AUTHENTICATED_USER_STORAGE_KEY } from '../constants/env';

const authUser = JSON.parse(localStorage.getItem(AUTHENTICATED_USER_STORAGE_KEY));
const initialState = {
  result: authUser ? authUser : null,
};

export default function authenticatedUser(state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED_USER:
      return {
        ...state,
        result: action.result,
      };

    case EMPTY_AUTHENTICATED_USER:
      return {
        result: null,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
