import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';

import ProductCard from './ProductCard';
import { APP_NAME, API_URL, APP_URL } from '../../constants/env';

import { fetchPaginatedProduct } from '../../actions/product';

export default function Product({ history }) {
  const SORTS = {
    'id desc': { label: 'Terbaru', value: 'id desc' },
    'price asc': { label: 'Termurah', value: 'price asc' },
    'price desc': { label: 'Termahal', value: 'price desc' },
    'name asc': { label: 'Nama A-Z', value: 'name asc' },
    'name desc': { label: 'Nama Z-A', value: 'name desc' },
  };
  const location = useLocation();

  const [productCategory, setProductCategory] = useState(null);
  const [sort, setSort] = useState('');

  const { result: productCategories } = useSelector((state) => state.productCategory);
  const { paginatedResult: paginatedProduct } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchPaginatedProductData();
    setProductCategoryData();
  }, [productCategories, location]);

  const fetchPaginatedProductData = async () => {
    const query = new URLSearchParams(location.search);
    const qSearch = query.get('s');
    const qCategory = query.get('category');
    const qSort = query.get('sort');
    const qPage = query.get('page');

    setSort(qSort);

    return dispatch(fetchPaginatedProduct(qSearch, qCategory, qSort, qPage)).then(() =>
      window.scrollTo(0, 0),
    );
  };

  const setProductCategoryData = () => {
    const query = new URLSearchParams(location.search);
    const qCategory = query.get('category');

    const categoriesAndSubCategories = [];
    productCategories.forEach((c) => {
      categoriesAndSubCategories.push(c);
      c.sub_categories.forEach((s) => categoriesAndSubCategories.push(s));
    });

    const selectedCategory = categoriesAndSubCategories.find((c) => c.url_slug === qCategory);
    if (selectedCategory) setProductCategory(selectedCategory);
  };

  const onSortChange = (e) => {
    const value = e.target.value;
    const query = new URLSearchParams(location.search);
    const qSearch = query.get('s') || '';
    const qCategory = query.get('category') || '';

    const newLocation = {
      pathname: '/product',
      search: `?s=${qSearch}&category=${qCategory}&sort=${value}&page=1`,
    };
    history.push(newLocation);
  };

  const formatPaginationLink = (url) => {
    if (!url) return `${location.pathname}${location.search}`;

    const query = new URLSearchParams(location.search);
    const qSearch = query.get('s');
    const qCategory = query.get('category');
    const qSort = query.get('sort');
    return `${url.replace(API_URL, '')}&s=${qSearch}&category=${qCategory}&sort=${qSort}`;
  };

  return (
    <div>
      <Helmet>
        <title>{`Cari Smartphone Berkualitas | ${APP_NAME}`}</title>
      </Helmet>

      {/* Category */}
      {productCategory && (
        <div className="mb-10">
          <Helmet>
            <title>{`${productCategory.meta_title || productCategory.name} | ${APP_NAME}`}</title>
            <meta name="description" content={productCategory.meta_description} />
            <meta name="keywords" content={productCategory.meta_keywords} />

            <meta
              property="og:title"
              content={`${productCategory.meta_title || productCategory.name} | ${APP_NAME}`}
            />
            <meta property="og:description" content={productCategory.meta_description} />
            <meta property="og:url" content={`${location.pathname}${location.search}`} />
            <meta
              name="twitter:title"
              content={`${productCategory.meta_title || productCategory.name} | ${APP_NAME}`}
            />
            <meta name="twitter:description" content={productCategory.meta_description} />
            <meta name="twitter:image" content={`${APP_URL}/${productCategory.image}`} />
          </Helmet>

          <h2 className="text-2xl font-bold uppercase">{productCategory.name}</h2>
          {productCategory.image && (
            <img
              className="w-full sm:w-48 h-auto"
              src={`${APP_URL}/${productCategory.image}`}
              alt={productCategory.name}
            />
          )}
        </div>
      )}

      <div className="flex">
        <h2 className="hidden md:block md:flex-1 text-2xl font-bold">
          {`${paginatedProduct.total} Produk telah ditemukan`}
        </h2>
        <div className="flex-1 text-right">
          Sort By
          <select className="ml-2 p-2 border rounded" defaultValue={sort} onChange={onSortChange}>
            {Object.values(SORTS).map((s) => (
              <option key={s.value} value={s.value}>
                {s.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mt-5 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {paginatedProduct.data &&
          paginatedProduct.data.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
      </div>

      {/* Pagination */}
      <div className="mt-10 py-3 flex items-center justify-between">
        <div className="flex flex-1 justify-between sm:hidden">
          {paginatedProduct.links && (
            <>
              {paginatedProduct.links[0].url && (
                <Link
                  className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  to={formatPaginationLink(paginatedProduct.links[0].url)}>
                  <span
                    dangerouslySetInnerHTML={{ __html: paginatedProduct.links[0].label }}></span>
                </Link>
              )}
              {paginatedProduct.links[paginatedProduct.links.length - 1].url && (
                <Link
                  className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                  to={formatPaginationLink(
                    paginatedProduct.links[paginatedProduct.links.length - 1].url,
                  )}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: paginatedProduct.links[paginatedProduct.links.length - 1].label,
                    }}></span>
                </Link>
              )}
            </>
          )}
        </div>

        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
          <div>
            <p className="pl-4 text-sm text-gray-700">
              Menampilkan data
              <span className="font-medium"> {paginatedProduct.from} </span>-
              <span className="font-medium"> {paginatedProduct.to} </span>
              dari
              <span className="font-medium"> {paginatedProduct.total} </span>
              produk
            </p>
          </div>
          <div>
            <nav
              className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
              aria-label="Pagination">
              {paginatedProduct.links &&
                paginatedProduct.links.map((link, index) => (
                  <Link
                    key={link.label}
                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                      ${index === 0 ? 'rounded-l-md' : ''}
                      ${index === paginatedProduct.links.length - 1 ? 'rounded-r-md' : ''}
                      ${
                        link.active
                          ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600'
                          : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'
                      }`}
                    to={formatPaginationLink(link.url)}>
                    <span dangerouslySetInnerHTML={{ __html: link.label }}></span>
                  </Link>
                ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
