import Swal from 'sweetalert2';
import { SUBMIT_SIGN_IN, SUBMIT_SIGN_UP, REQUEST_OTP, VALIDATE_OTP } from './_types';
import {
  API_URL,
  API_HEADER,
  API_RESPONSE_STATUS,
  API_RESPONSE_MESSAGE,
  API_RESPONSE,
  APP_NAME,
} from '../constants/env';
import { setAuthenticatedUser, removeAuthenticatedUser } from './authenticatedUser';

/**
 * @param data      phone, password
 */
export const submitSignIn = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/login`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire(
              'Login Success',
              `Login ke ${APP_NAME} telah berhasil, selamat berbelanja`,
              'success',
            );
            dispatch(setAuthenticatedUser(response.data));
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Login Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: SUBMIT_SIGN_IN, result: response });
      });
  };
};

export const submitSignOut = () => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch(removeAuthenticatedUser());
      Swal.fire('LogOut Success', `LogOut dari ${APP_NAME} berhasil`, 'success');
      resolve();
    });
  };
};

/**
 * @param data      phone, first_name, last_name, password
 */
export const submitSignUp = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/register`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Registration Success', response[API_RESPONSE_MESSAGE], 'success');
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Registration Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: SUBMIT_SIGN_UP, result: response });
      });
  };
};

/**
 * @param phone
 */
export const requestOtp = (phone) => {
  return (dispatch) => {
    return fetch(`${API_URL}/request-otp`, {
      method: 'POST',
      body: JSON.stringify({ phone }),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Request OTP Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: REQUEST_OTP, result: response });
      });
  };
};

/**
 * @param phone
 * @param otp
 */
export const validateOtp = (phone, otp) => {
  return (dispatch) => {
    return fetch(`${API_URL}/validate-otp`, {
      method: 'POST',
      body: JSON.stringify({ phone, otp }),
      headers: API_HEADER,
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('OTP Verification Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
        dispatch({ type: VALIDATE_OTP, result: response });
      });
  };
};
