import {
  FETCH_PRODUCT,
  FETCH_FEATURED_PRODUCT,
  FETCH_PAGINATED_PRODUCT,
  FETCH_PRODUCT_DETAIL,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  featuredResult: [],
  paginatedResult: {},

  detailResult: {},
};

export default function product(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT:
      return {
        ...state,
        result: action.result,
      };

    case FETCH_FEATURED_PRODUCT:
      return {
        ...state,
        featuredResult: action.result,
      };

    case FETCH_PAGINATED_PRODUCT:
      return {
        ...state,
        paginatedResult: action.result,
      };

    case FETCH_PRODUCT_DETAIL:
      return {
        ...state,
        detailResult: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
