import React from 'react';
import { useSelector } from 'react-redux';

import { APP_URL } from '../../constants/env';
import Utils from '../../helpers/utils';

export default function CheckoutSummary() {
  const checkout = useSelector((state) => state.checkout);

  return (
    <>
      <div className="py-4 px-5 mt-4 w-full rounded-xl border border-gray-200 lg:sticky lg:top-28">
        <h4 className="text-xl font-bold">Checkout Summary</h4>
        <div>
          {checkout.details?.map((detail) => (
            <div key={detail.time} className="flex mt-6">
              <img
                className="object-contain object-center w-16 h-16 rounded-xl"
                src={`${APP_URL}/${detail.product.image_1}`}
                alt={detail.product.name}
              />
              <div className="flex-1 pl-5 text-sm">
                <h2 className="leading-none font-bold">{detail.product.name}</h2>
                {detail.variant && (
                  <div className="text-gray-400">Variasi: {detail.variant.name}</div>
                )}
                <div className="mt-2 font-bold">
                  Rp {Utils.numberFormat(detail.product.price * detail.quantity, 0)}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6">
          <div className="mt-2 flex items-center">
            <div>Sub Total</div>
            <div className="ml-auto">Rp {Utils.numberFormat(checkout.total)}</div>
          </div>
          <div className="mt-2 flex items-center">
            <div>Voucher {checkout.voucher_code}</div>
            <div className="ml-auto">- Rp {Utils.numberFormat(checkout.voucher_discount)}</div>
          </div>
          <div className="mt-2 flex items-center">
            <div>Ongkos Kirim</div>
            <div className="ml-auto">Rp {Utils.numberFormat(checkout.total_shipping_fee)}</div>
          </div>
          <div className="mt-2 flex items-center">
            <div>Biaya Asuransi</div>
            <div className="ml-auto">Rp {Utils.numberFormat(checkout.total_insurance_fee)}</div>
          </div>
          <div className="mt-2 pt-2 flex items-center border-t font-bold">
            <div>Total</div>
            <div className="ml-auto">Rp {Utils.numberFormat(checkout.grand_total)}</div>
          </div>
        </div>
      </div>
    </>
  );
}
