import React, { useState, useEffect } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { HeartIcon } from '@heroicons/react/solid';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import RatingStar from '../../../components/RatingStar';
import { APP_NAME, APP_URL } from '../../../constants/env';
import Utils from '../../../helpers/utils';
import FeaturedProduct from '../FeaturedProduct';

import { fetchProductDetail } from '../../../actions/product';
import { addToCart } from '../../../actions/cart';
import { submitWishlist, deleteWishlist } from '../../../actions/wishlist';

export default function ProductDetail() {
  const history = useHistory();
  const location = useLocation();
  const { slug } = useParams();

  const { detailResult: product } = useSelector((state) => state.product);
  const { result: wishlists } = useSelector((state) => state.wishlist);
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const discount = Number(product?.original_price) - Number(product?.price);
  const discountPercentage =
    Number(product?.original_price) > 0 ? (discount / Number(product?.original_price)) * 100 : 0;

  const [isFetchingData, setIsFetchingData] = useState(true);
  const [onlineStock, setOnlineStock] = useState(1);
  const [offlineStocks, setOfflineStocks] = useState([]);

  const [selectedImage, setSelectedImage] = useState('');
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [storedInWishlist, setStoredInWishlist] = useState(null);

  //

  useEffect(() => {
    dispatch(fetchProductDetail(slug)).then(() => {
      window.scrollTo(0, 0);
      setIsFetchingData(false);
    });
  }, [dispatch, slug]);

  useEffect(() => {
    if (product.id) {
      setSelectedImage(product.image_1);
      setOnlineStock(Number(product.total_online_stock));
      setOfflineStocks(product.product_stocks);
      if (product.product_variants.length > 0)
        onSelectProductVariant(
          product.product_variants.filter((variant) => Number(variant.total_online_stock) > 0)[0],
        );
    }
  }, [product]);

  useEffect(() => {
    const selectedWishlist = wishlists.find((w) => w.product_id === product.id);
    setStoredInWishlist(selectedWishlist ? selectedWishlist : null);
  }, [product, wishlists]);

  //

  const onSelectProductVariant = (variant) => {
    setOnlineStock(Number(variant.total_online_stock));
    setOfflineStocks(variant.product_stocks);
    setSelectedVariant(variant.id);
    setQuantity(1);
  };

  //

  const onClickMinusQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };

  const onClickPlusQuantity = () => {
    if (quantity < onlineStock) setQuantity(quantity + 1);
  };

  const onClickAddToCart = () => {
    const time = moment().format('x');
    const variant = selectedVariant
      ? product.product_variants.find((v) => v.id === selectedVariant)
      : null;

    const cartData = { time, product, variant, quantity, onlineStock, offlineStocks };
    dispatch(addToCart(cartData));
  };

  //

  const onClickWishlist = () => {
    // Redirect if not Logged In
    if (!authenticatedUser) history.push('/sign-in');

    if (storedInWishlist) {
      dispatch(deleteWishlist(storedInWishlist.id));
    } else {
      dispatch(
        submitWishlist({
          product_id: product.id,
          price: product.price,
        }),
      );
    }
  };

  //

  if (isFetchingData) {
    return (
      <section className="flex flex-wrap justify-center py-10">
        <svg
          className="animate-spin -ml-1 mr-3 h-10 w-10 text-purple-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </section>
    );
  }
  return (
    <>
      <Helmet>
        <title>{`${product.meta_title || product.name} | ${APP_NAME}`}</title>
        <meta name="description" content={product.meta_description} />
        <meta name="keywords" content={product.meta_keywords} />

        <meta property="og:title" content={`${product.meta_title || product.name} | ${APP_NAME}`} />
        <meta property="og:description" content={product.meta_description} />
        <meta property="og:url" content={`${location.pathname}${location.search}`} />
        <meta
          name="twitter:title"
          content={`${product.meta_title || product.name} | ${APP_NAME}`}
        />
        <meta name="twitter:description" content={product.meta_description} />
        <meta name="twitter:image" content={`${APP_URL}/${product.image_1}`} />
      </Helmet>

      <section className="my-10 grid md:grid-cols-2 gap-x-8 gap-y-10">
        <div className="w-full">
          <img
            className="w-full aspect-square object-cover object-center rounded border"
            src={`${APP_URL}/${selectedImage}`}
            alt={product.name}
          />

          <div className="mt-2 grid grid-cols-5 gap-2">
            {[1, 2, 3, 4, 5].map(
              (image) =>
                product[`image_${image}`] && (
                  <img
                    key={image}
                    className="w-full aspect-square object-cover object-center rounded border cursor-pointer"
                    src={`${APP_URL}/${product[`image_${image}`]}`}
                    alt={product.name}
                    onClick={() => setSelectedImage(product[`image_${image}`])}
                  />
                ),
            )}
          </div>
        </div>

        <div className="w-full">
          {/* PRODUCT TITLE */}
          <h2 className="text-gray-500 text-sm tracking-widest uppercase">
            {product.product_category?.name}
          </h2>
          <h1 className="text-gray-900 text-3xl font-medium">{product.name}</h1>
          {product.star > 0 && <RatingStar editable={false} size={7} defaultValue={product.star} />}

          {/* PRODUCT PRICE & DISCOUNT */}
          <div className="mt-4">
            <div className="text-gray-900 text-2xl font-bold">
              {`Rp ${Utils.numberFormat(product.price, 2)}`}
            </div>
            {discountPercentage > 0 && (
              <div className="mt-1 flex items-center">
                <div className="px-2 bg-red-100 rounded text-red-600 font-bold">
                  {` ${Utils.numberFormat(discountPercentage)}%`}
                </div>
                <div className="ml-2 text-gray-300 line-through">
                  Rp {Utils.numberFormat(product.original_price)}
                </div>
              </div>
            )}
          </div>

          {/* ADD TO CART FORM */}
          <div className="mt-10 pb-6 border-b-2 border-gray-100">
            {product.status === 1 && Number(product.total_online_stock) > 0 ? (
              <>
                <div className="grid grid-cols-3 gap-3">
                  {product.product_variants
                    .filter((variant) => Number(variant.total_online_stock) > 0)
                    .map((variant) => (
                      <button
                        key={variant.id}
                        className={`p-3 rounded border focus:outline-none transition-all
                          ${selectedVariant === variant.id ? 'border-gray-600' : 'border-gray-200'}
                          hover:border-gray-600 font-medium uppercase`}
                        onClick={() => onSelectProductVariant(variant)}>
                        {variant.name}
                      </button>
                    ))}
                </div>

                <div className="mt-6 flex items-center">
                  <div className="flex">
                    <button
                      className="px-3 border border-r-0 rounded-l transition-all
                          bg-white hover:bg-gray-100"
                      onClick={onClickMinusQuantity}>
                      <MinusIcon className="h-3 w-3" />
                    </button>
                    <input
                      type="number"
                      className="border pl-3 pr-0 py-2 w-20 text-lg text-center"
                      value={quantity}
                      min="1"
                      max={onlineStock}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                    <button
                      className="px-3 border border-l-0 rounded-r transition-all
                          bg-white hover:bg-gray-100"
                      onClick={onClickPlusQuantity}>
                      <PlusIcon className="h-3 w-3" />
                    </button>
                  </div>

                  <button
                    className="ml-2 px-6 py-2 rounded focus:outline-none transition-all
                      bg-purple-600 hover:bg-purple-700 text-white text-lg font-medium"
                    onClick={onClickAddToCart}>
                    Add to Cart
                  </button>

                  <button
                    className={`p-0 ml-auto w-12 h-12 inline-flex items-center justify-center rounded-full
                      ${storedInWishlist ? 'bg-red-500' : 'bg-gray-200'}`}
                    onClick={onClickWishlist}>
                    <HeartIcon
                      className={`w-5 h-5 ${storedInWishlist ? 'text-white' : 'text-gray-500'}`}
                    />
                  </button>
                </div>
              </>
            ) : (
              <div className="mt-6 flex items-center">
                <button
                  className="px-6 py-2 rounded focus:outline-none transition-all
                    bg-red-600 text-white text-lg font-medium">
                  Stock Tidak Tersedia
                </button>
                <button
                  className={`p-0 ml-auto w-12 h-12 inline-flex items-center justify-center rounded-full
                      ${storedInWishlist ? 'bg-red-500' : 'bg-gray-200'}`}
                  onClick={onClickWishlist}>
                  <HeartIcon
                    className={`w-5 h-5 ${storedInWishlist ? 'text-white' : 'text-gray-500'}`}
                  />
                </button>
              </div>
            )}
          </div>

          {/* DESCRIPTION CONTENT */}
          <div className="mt-6">
            <p
              className="leading-relaxed"
              dangerouslySetInnerHTML={{ __html: product.description }}></p>
          </div>
        </div>
      </section>

      <div className="mt-10">
        <FeaturedProduct />
      </div>
    </>
  );
}
