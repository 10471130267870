import { FETCH_CART, UPDATE_CART, EMPTY_CART } from '../actions/_types';

const initialState = {
  result: [],
};

export default function cart(state = initialState, action) {
  switch (action.type) {
    case FETCH_CART:
    case UPDATE_CART:
      return {
        ...state,
        result: action.result,
      };

    case EMPTY_CART:
      return {
        ...state,
        result: [],
      };

    default:
      return state;
  }
}
