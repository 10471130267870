import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import Collapsible from '../../../../components/Collapsible';
import PaymentConfirmationForm from './ConfirmationForm';
import { SubmitButton } from '../../../../components/Base';
import Utils from '../../../../helpers/utils';

export default function PaymentTable() {
  const cellClass = 'px-2 py-2 border-b text-sm';
  const paymentConfirmationFormRef = useRef();
  const history = useHistory();
  const location = useLocation();

  const { detailResult: order } = useSelector((state) => state.order);
  const { result: settings } = useSelector((state) => state.settings);

  useEffect(() => {
    if (location.search && order) {
      const query = new URLSearchParams(location.search);
      const source = query.get('src');
      const paymentRefNo = query.get('ref');
      if (source === 'payment') {
        const payment = order.order_payments.find((p) => p.hash === paymentRefNo);
        if (payment.is_paid === 1) {
          Swal.fire(
            'Payment Success',
            `Pembayaran Anda melalui ${payment.payment_method_name} telah berhasil dilakukan`,
            'success',
          );
        } else {
          Swal.fire('Payment Failed', 'Pembayaran Transaksi Anda telah dibatalkan', 'error');
        }
      }
    }
  }, [location, order]);

  /* const onClickPayment = (payment) => {
    if (payment.xendit_invoice_url) {
      const newWindow = window.open(payment.xendit_invoice_url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } else {
      paymentConfirmationFormRef.current.showForm(payment);
    }
  }; */

  const onClickPayment = (payment) => {
    if (payment.payment_gateway_id) {
      history.push(`/order/payment/${payment.hash}`);
    } else {
      paymentConfirmationFormRef.current.showForm(payment);
    }
  };

  return (
    <Collapsible title="Pembayaran">
      <PaymentConfirmationForm ref={paymentConfirmationFormRef} />

      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left"></th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              #
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Metode Pembayaran
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Total
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Status
            </th>
            <th className="p-2 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 font-bold text-left">
              Confirmed
            </th>
          </tr>
        </thead>

        <tbody>
          {order.order_payments.map((payment, index) => (
            <tr key={payment.id}>
              <td className={cellClass}>
                {payment.is_paid === 0 && (
                  <SubmitButton
                    color="purple"
                    textClass="text-white text-xs"
                    paddingClass="px-3 py-2 mr-1"
                    text="Bayar"
                    onClick={() => onClickPayment(payment)}
                  />
                )}
              </td>
              <td className={cellClass}>{`Pembayaran 0${index + 1}`}</td>
              <td className={cellClass}>{payment.payment_method_name}</td>
              <td className={cellClass}>{Utils.numberFormat(payment.total, 0)}</td>
              <td className={cellClass}>
                {payment.is_paid === -1 && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    Expired
                  </span>
                )}
                {payment.is_paid === 0 && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Pending
                  </span>
                )}
                {payment.is_paid === 1 && (
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                    Paid
                  </span>
                )}
              </td>
              <td className={cellClass}>
                {payment.is_confirmed === 1 && <span className="text-green-600">✓</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="mt-2 text-sm">
        <p dangerouslySetInnerHTML={{ __html: settings?.payment_remarks }}></p>
      </div>
    </Collapsible>
  );
}
