import Swal from 'sweetalert2';
import {
  FETCH_ORDER,
  FETCH_ORDER_DETAIL,
  SUBMIT_ORDER,
  REFRESH_ORDER_DATATABLES,
  SUBMIT_ORDER_PAYMENT_CONFIRMATION,
  ACCEPT_REJECT_PAYMENT_CONFIRMATION,
  REFUND_ORDER,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader, authApiHeaderMultipart } from '../helpers/apiHeader';
import { emptyCart } from '../actions/cart';

export const fetchOrder = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/order`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_ORDER, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_ORDER, result: [] });
            Swal.fire('Fetch Order Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshOrderDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_ORDER_DATATABLES });
  };
};

export const setOrderDetail = (order) => {
  return (dispatch) => {
    return dispatch({ type: FETCH_ORDER_DETAIL, result: { ...order } });
  };
};

export const fetchOrderDetail = (hash) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/${hash}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_ORDER_DETAIL, result: { ...response.data } });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_ORDER_DETAIL, result: {} });
            Swal.fire('Fetch Order Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

/**
 * @param data      shipping_first_name, shipping_last_name, shipping_phone,
 *                  street_address, district_id, district, city_id, city, province_id, province, zip_code,
 *                  shipping_method_id, shipping_method_name, shipping_method_detail, total_weight,
 *                  shipping_fee, additional_shipping_fee, total_shipping_fee,
 *                  total, voucher_id, voucher_code, voucher_discount, payment_fee, grand_total,
 *                  details[{ product_id, product_variant_id, price, quantity, weight, total_weight }],
 *                  payments[{ payment_method_id, amount, fee_amount, total }],
 */
export const submitOrder = (data) => {
  return (dispatch) => {
    return (
      fetch(`${API_URL}/order`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: authApiHeader(),
      })
        .then((response) => response.json())
        .then((response) => {
          switch (response[API_RESPONSE_STATUS]) {
            case API_RESPONSE.SUCCESS:
              // Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
              dispatch({ type: SUBMIT_ORDER, result: response });
              break;

            case API_RESPONSE.FAILED:
            default:
              Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
              return Promise.reject(response[API_RESPONSE_MESSAGE]);
          }
        })
        .then(() => dispatch(emptyCart()))
        .then(() => dispatch(refreshOrderDatatables()))
        // .then(() => dispatch(fetchOrder()))
        .then(() => dispatch({ type: SUBMIT_ORDER, result: {} }))
    );
  };
};

/**
 * @param id
 * @param data      resi_no
 */
export const updateOrder = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_ORDER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOrderDatatables()))
      .then(() => dispatch(fetchOrder()));
  };
};

/**
 * @param id
 */
/* export const deleteOrder = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then(response => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_ORDER, result: response });
            break;
        
          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOrderDatatables()))
      .then(() => dispatch(fetchOrder()));
  };
}; */

/**
 * @param data      FormData: order_payment_id, payment_receipt
 */
export const submitPaymentConfirmation = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/confirm-payment`, {
      method: 'POST',
      body: data,
      headers: authApiHeaderMultipart(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_ORDER_PAYMENT_CONFIRMATION, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOrderDatatables()))
      .then(() => dispatch(fetchOrder()));
  };
};

/**
 * @param data      order_payment_id
 */
export const acceptPaymentConfirmation = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/accept-payment`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: ACCEPT_REJECT_PAYMENT_CONFIRMATION, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOrderDatatables()))
      .then(() => dispatch(fetchOrder()));
  };
};

/**
 * @param data      order_payment_id
 */
export const rejectPaymentConfirmation = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/reject-payment`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: ACCEPT_REJECT_PAYMENT_CONFIRMATION, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOrderDatatables()))
      .then(() => dispatch(fetchOrder()));
  };
};

/**
 * @param data      refund_pin
 */
export const refundOrder = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/order/${id}/refund`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Refund Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: REFUND_ORDER, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Refund Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshOrderDatatables()))
      .then(() => dispatch(fetchOrder()));
  };
};
