import Swal from 'sweetalert2';
import {
  FETCH_PRODUCT,
  FETCH_FEATURED_PRODUCT,
  FETCH_PAGINATED_PRODUCT,
  FETCH_PRODUCT_DETAIL,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchProduct = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/product`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PRODUCT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PRODUCT, result: [] });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchFeaturedProduct = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/product?active=true&featured=true`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_FEATURED_PRODUCT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_FEATURED_PRODUCT, result: [] });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const fetchPaginatedProduct = (search, category, sort, page) => {
  return (dispatch) => {
    let queryString = '';
    if (search && search !== '') queryString += `&s=${search}`;
    if (category) queryString += `&category=${category}`;
    if (sort) queryString += `&sort=${sort}`;
    if (page) queryString += `&page=${page}`;

    return fetch(`${API_URL}/product?source=pagination&active=true${queryString}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PAGINATED_PRODUCT, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PAGINATED_PRODUCT, result: [] });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const setProductDetail = (product) => {
  return (dispatch) => {
    return dispatch({ type: FETCH_PRODUCT_DETAIL, result: { ...product } });
  };
};

export const fetchProductDetail = (slug) => {
  return (dispatch) => {
    return fetch(`${API_URL}/product/${slug}`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_PRODUCT_DETAIL, result: { ...response.data } });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_PRODUCT_DETAIL, result: {} });
            Swal.fire('Fetch Product Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};
