import { FETCH_WAREHOUSE, RESET_STORE } from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function warehouse(state = initialState, action) {
  switch (action.type) {
    case FETCH_WAREHOUSE:
      return {
        ...state,
        result: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
