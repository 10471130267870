import {
  FETCH_WISHLIST,
  SUBMIT_WISHLIST,
  DELETE_WISHLIST,
  REFRESH_WISHLIST_DATATABLES,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  submitResult: {},
  deleteResult: {},

  refresh: false,
};

export default function wishlist(state = initialState, action) {
  switch (action.type) {
    case FETCH_WISHLIST:
      return {
        ...state,
        result: action.result,
      };

    case SUBMIT_WISHLIST:
      return {
        ...state,
        submitResult: action.result,
      };

    case DELETE_WISHLIST:
      return {
        ...state,
        deleteResult: action.result,
      };

    case REFRESH_WISHLIST_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
