import Swal from 'sweetalert2';
import {
  FETCH_WISHLIST,
  SUBMIT_WISHLIST,
  DELETE_WISHLIST,
  REFRESH_WISHLIST_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchWishlist = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/wishlist`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_WISHLIST, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_WISHLIST, result: [] });
            Swal.fire('Fetch Wishlist Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshWishlistDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_WISHLIST_DATATABLES });
  };
};

/**
 * @param data      product_id, price
 */
export const submitWishlist = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/wishlist`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            // Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_WISHLIST, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            // Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshWishlistDatatables()))
      .then(() => dispatch(fetchWishlist()));
  };
};

/**
 * @param id
 */
export const deleteWishlist = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/wishlist/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            // Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_WISHLIST, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            // Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshWishlistDatatables()))
      .then(() => dispatch(fetchWishlist()));
  };
};
