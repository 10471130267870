import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

import Modal from '../Modal';
import BasicButton from './BasicButton';
import SubmitButton from './SubmitButton';

export default function DeleteButton({
  text = 'Delete',
  isLoading = false,
  paddingClass = 'px-5 py-3',
  textClass = 'text-white text-sm',
  additionalClass = '',
  onClick = () => {},
  ...props
}) {
  const isLoadingClass = isLoading ? 'cursor-not-allowed' : '';
  const [isVisible, setIsVisible] = useState(false);

  const showConfirmationModal = () => setIsVisible(true);
  const closeConfirmationModal = () => setIsVisible(false);

  const onButtonClick = () => {
    if (isLoading) return;
    showConfirmationModal();
  };

  const onSubmit = () => {
    closeConfirmationModal();
    onClick();
  };

  return (
    <>
      <div className="relative inline">
        <button
          className={`inline-flex justify-center items-center rounded
            bg-red-400 hover:bg-red-500 active:bg-red-600 font-bold uppercase 
            shadow hover:shadow-lg outline-none focus:outline-none
            ease-linear transition-all duration-150
            ${paddingClass} ${textClass} ${isLoadingClass} ${additionalClass}`}
          {...props}
          onClick={onButtonClick}
          disabled={isLoading}>
          {isLoading && (
            <svg
              className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          )}
          {text}
        </button>
      </div>

      <Modal
        isVisible={isVisible}
        size="small"
        onClose={setIsVisible}
        ModalContent={
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                Delete Confirmation
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Are you sure you want to delete? The data will be permanently removed. This action
                  cannot be undone.
                </p>
              </div>
            </div>
          </div>
        }
        ModalButton={
          <>
            <SubmitButton
              text="Delete"
              textClass="text-white text-xs"
              color="red"
              onClick={onSubmit}
            />
            <BasicButton
              text="Cancel"
              textClass="text-white text-xs mr-2"
              onClick={closeConfirmationModal}
            />
          </>
        }
      />
    </>
  );
}
