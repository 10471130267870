import React from 'react';

export default function InlineSwitch({
  title,
  inputRef,
  errorMessage,
  inputWidth = 'w-full',
  ...props
}) {
  return (
    <>
      <div className="relative w-full mb-5">
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
          <div>
            <label className="block uppercase text-gray-700 text-xs font-bold">{title}</label>
          </div>

          <div className="col-span-2 xl:col-span-3">
            <div className={`relative flex flex-wrap items-stretch ${inputWidth}`}>
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                  ref={inputRef}
                  type="checkbox"
                  name="toggle"
                  id={title}
                  {...props}
                />
                <label
                  htmlFor={title}
                  className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
              </div>
            </div>

            {errorMessage && <span className="text-xs text-red-500">{errorMessage}</span>}
          </div>
        </div>
      </div>
    </>
  );
}
