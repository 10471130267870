import React, { useEffect } from 'react';
import SlickSlider from 'react-slick';
import { useSelector, useDispatch } from 'react-redux';

import { fetchSlider } from '../../actions/slider';
import { APP_URL } from '../../constants/env';

export default function Slider() {
  const sliderSettings = {
    arrows: false,
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { result: sliders } = useSelector((state) => state.slider);
  const dispatch = useDispatch();

  // Fetch Initial Data
  useEffect(() => {
    dispatch(fetchSlider());
  }, []);

  return (
    <div>
      <SlickSlider {...sliderSettings}>
        {sliders.map((slider) => (
          <div key={slider.id} className="cursor-pointer">
            <a href={slider.url} target="_blank" rel="noreferrer">
              <img src={`${APP_URL}/${slider.image}`} alt="BStore" className="w-full" />
            </a>
          </div>
        ))}
      </SlickSlider>
    </div>
  );
}
