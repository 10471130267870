import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';

import Navbar from './Navbar';
import Footer from './Footer';
import PrivateRoute from '../PrivateRoute';

// Views
import Slider from './Slider';
import Home from './Home';
import Login from './Auth/Login';
import Register from './Auth/Register';
import SignOut from './Auth/SignOut';

import Product from './Product';
import ProductDetail from './Product/Detail';
import Cart from './Cart';
import Checkout from './Checkout';
import Profile from './Profile';
import OrderHistoryDetail from './Profile/OrderHistory/Detail';
import Ipay88RedirectForm from './Ipay88/RedirectForm';

import Page from './Page';

import { fetchPage } from '../actions/page';
import { fetchPaymentMethod } from '../actions/paymentMethod';
import { fetchShippingMethod } from '../actions/shippingMethod';
import { fetchWarehouse } from '../actions/warehouse';
import { fetchSettings } from '../actions/settings';
import { fetchProductCategory, fetchFeaturedProductCategory } from '../actions/productCategory';
import { fetchFeaturedProduct } from '../actions/product';
import { fetchCart } from '../actions/cart';
import { fetchWishlist } from '../actions/wishlist';

export default function Front() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPage());
    dispatch(fetchPaymentMethod());
    dispatch(fetchShippingMethod());
    dispatch(fetchWarehouse());
    dispatch(fetchSettings());
    dispatch(fetchProductCategory());
    dispatch(fetchFeaturedProductCategory());
    dispatch(fetchFeaturedProduct());
    dispatch(fetchCart());
  }, [dispatch]);

  useEffect(() => {
    if (authenticatedUser) dispatch(fetchWishlist());
  }, [dispatch, authenticatedUser]);

  return (
    <>
      <div className="fixed top-0 inset-x-0 z-10 w-full">
        <Navbar />
      </div>

      <div className="mt-20 text-gray-600 body-font">
        <Switch>
          <Route path="/" exact component={Slider} />
        </Switch>
        <div className="max-w-7xl mx-auto pt-12 px-4">
          <Switch>
            <Route path="/" exact component={Home} />
            <Redirect from="/home" to="/" />

            <Route path="/sign-in" exact component={Login} />
            <Route path="/sign-up" exact component={Register} />
            <PrivateRoute path="/sign-out" exact component={SignOut} />

            <Route path="/product" exact component={Product} />
            <Route path="/product/:slug" exact component={ProductDetail} />

            <Route path="/cart" exact component={Cart} />
            <Route path="/checkout" exact component={Checkout} />

            <Route path="/order/guest/:hash" exact component={OrderHistoryDetail} />
            <Route path="/order/payment/:hash" exact component={Ipay88RedirectForm} />

            <PrivateRoute path="/profile" exact component={Profile} />
            <PrivateRoute path="/order-history" exact component={Profile} />
            <PrivateRoute path="/order-history/:hash" exact component={Profile} />
            <PrivateRoute path="/wishlist" exact component={Profile} />
            <PrivateRoute path="/user-address" exact component={Profile} />
            <PrivateRoute path="/password" exact component={Profile} />

            <Route path="/article/:slug" exact component={Page} />

            <Redirect from="*" to="/" />
          </Switch>
        </div>
      </div>

      <div className="mt-10 text-gray-600 body-font">
        <Footer />
      </div>
    </>
  );
}
