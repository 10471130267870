import { SET_AUTHENTICATED_USER, EMPTY_AUTHENTICATED_USER } from './_types';
import { AUTHENTICATED_USER_STORAGE_KEY } from '../constants/env';

export const setAuthenticatedUser = (data) => {
  return (dispatch) => {
    localStorage.setItem(AUTHENTICATED_USER_STORAGE_KEY, JSON.stringify(data));
    dispatch({ type: SET_AUTHENTICATED_USER, result: data });
  };
};

export const removeAuthenticatedUser = () => {
  return (dispatch) => {
    localStorage.removeItem(AUTHENTICATED_USER_STORAGE_KEY);
    dispatch({ type: EMPTY_AUTHENTICATED_USER });
  };
};
