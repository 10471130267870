/* AUTHENTICATED DATA */
export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const EMPTY_AUTHENTICATED_USER = 'EMPTY_AUTHENTICATED_USER';

export const RESET_STORE = 'RESET_STORE';

/* AUTHENTICATION */
export const SUBMIT_SIGN_IN = 'SUBMIT_SIGN_IN';
export const SUBMIT_SIGN_OUT = 'SUBMIT_SIGN_OUT';

export const SUBMIT_SIGN_UP = 'SUBMIT_SIGN_UP';
export const REQUEST_OTP = 'REQUEST_OTP';
export const VALIDATE_OTP = 'VALIDATE_OTP';

/* PROFILE & PASSWORD */
export const SUBMIT_CHANGE_PROFILE = 'SUBMIT_CHANGE_PROFILE';
export const SUBMIT_CHANGE_PROFILE_PICTURE = 'SUBMIT_CHANGE_PROFILE_PICTURE';
export const SUBMIT_CHANGE_PASSWORD = 'SUBMIT_CHANGE_PASSWORD';

/* RAJA ONGKIR */
export const FETCH_PROVINCE = 'FETCH_PROVINCE';
export const FETCH_CITY = 'FETCH_CITY';
export const FETCH_DISTRICT = 'FETCH_DISTRICT';
export const FETCH_SHIPPING_FEE = 'FETCH_SHIPPING_FEE';

//

/* MASTER & CONFIGURATION DATA */
export const FETCH_PAGE = 'FETCH_PAGE';
export const FETCH_SLIDER = 'FETCH_SLIDER';
export const FETCH_PAYMENT_METHOD = 'FETCH_PAYMENT_METHOD';
export const FETCH_SHIPPING_METHOD = 'FETCH_SHIPPING_METHOD';
export const FETCH_WAREHOUSE = 'FETCH_WAREHOUSE';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';

/* PRODUCT & PRODUCT CATEGORY */
export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY';
export const FETCH_FEATURED_PRODUCT_CATEGORY = 'FETCH_FEATURED_PRODUCT_CATEGORY';

export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_FEATURED_PRODUCT = 'FETCH_FEATURED_PRODUCT';
export const FETCH_PAGINATED_PRODUCT = 'FETCH_PAGINATED_PRODUCT';
export const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';

/* USER ADDRESS */
export const FETCH_USER_ADDRESS = 'FETCH_USER_ADDRESS';
export const SUBMIT_USER_ADDRESS = 'SUBMIT_USER_ADDRESS';
export const DELETE_USER_ADDRESS = 'DELETE_USER_ADDRESS';
export const REFRESH_USER_ADDRESS_DATATABLES = 'REFRESH_USER_ADDRESS_DATATABLES';

/* WISHLIST */
export const FETCH_WISHLIST = 'FETCH_WISHLIST';
export const SUBMIT_WISHLIST = 'SUBMIT_WISHLIST';
export const DELETE_WISHLIST = 'DELETE_WISHLIST';
export const REFRESH_WISHLIST_DATATABLES = 'REFRESH_WISHLIST_DATATABLES';

//

/* CART */
export const FETCH_CART = 'FETCH_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const EMPTY_CART = 'EMPTY_CART';

/* CHECKOUT */
export const ADD_CART_TO_CHECKOUT = 'ADD_CART_TO_CHECKOUT';
export const SET_IS_EDITING_USER_INFORMATION = 'SET_IS_EDITING_USER_INFORMATION';
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const SET_SHIPPING_TYPE = 'SET_SHIPPING_TYPE';
export const SET_SHIPPING_WAREHOUSE = 'SET_SHIPPING_WAREHOUSE';
export const SET_SHIPPING_METHOD = 'SET_SHIPPING_METHOD';
export const SET_SHIPPING_METHOD_TYPE = 'SET_SHIPPING_METHOD_TYPE';
export const APPLY_VOUCHER = 'APPLY_VOUCHER';

/* ORDER */
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDER_DETAIL = 'FETCH_ORDER_DETAIL';
export const SUBMIT_ORDER = 'SUBMIT_ORDER';
export const REFRESH_ORDER_DATATABLES = 'REFRESH_ORDER_DATATABLES';
export const SUBMIT_ORDER_PAYMENT_CONFIRMATION = 'SUBMIT_ORDER_PAYMENT_CONFIRMATION';
export const ACCEPT_REJECT_PAYMENT_CONFIRMATION = 'ACCEPT_REJECT_PAYMENT_CONFIRMATION';
export const REFUND_ORDER = 'REFUND_ORDER';

/* REVIEW */
export const FETCH_REVIEW = 'FETCH_REVIEW';
export const SUBMIT_REVIEW = 'SUBMIT_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const REFRESH_REVIEW_DATATABLES = 'REFRESH_REVIEW_DATATABLES';
