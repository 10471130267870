import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import Collapsible from '../../components/Collapsible';
import { InlineTextInput, InlineTextArea, InlineSelect, SubmitButton } from '../../components/Base';

import { fetchProvince, fetchCity, fetchDistrict } from '../../actions/rajaongkir';
import { setIsEditingUserInformation, setUserInformation } from '../../actions/checkout';

export default function UserInformationForm() {
  const { register, errors, handleSubmit } = useForm();

  const [provinceName, setProvinceName] = useState('');
  const [cityName, setCityName] = useState('');
  const [districtName, setDistrictName] = useState('');

  const { provinces, cities, districts } = useSelector((state) => state.rajaongkir);
  const checkout = useSelector((state) => state.checkout);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProvince());
  }, []);

  const onChangeProvince = (e) => {
    const selectedProvince = provinces.find((p) => p.province_id === e.target.value);
    setProvinceName(selectedProvince.province);
    // setValue('province', selectedProvince.province);
    dispatch(fetchCity(selectedProvince.province_id));
  };

  const onChangeCity = (e) => {
    const selectedCity = cities.find((c) => c.city_id === e.target.value);
    setCityName(selectedCity.city_name);
    // setValue('city', selectedCity.city_name);
    dispatch(fetchDistrict(selectedCity.city_id));
  };

  const onChangeDistrict = (e) => {
    const selectedDistrict = districts.find((d) => d.subdistrict_id === e.target.value);
    setDistrictName(selectedDistrict.subdistrict_name);
    // setValue('district', selectedDistrict.subdistrict_name);
  };

  const onSubmitForm = (data) => {
    data.shipping_first_name = data.first_name;
    data.shipping_last_name = data.last_name;
    data.shipping_phone = data.phone;
    data.province = provinceName;
    data.city = cityName;
    data.district = districtName;

    dispatch(setIsEditingUserInformation(false));
    dispatch(setUserInformation(data));
    window.scrollTo(0, 0);
  };

  const onClickEdit = () => {
    dispatch(setIsEditingUserInformation(true));
  };

  return (
    <Collapsible title="Data Diri dan Alamat">
      {checkout.isEditingUserInformation ? (
        <form id="userInformationForm" onSubmit={handleSubmit(onSubmitForm)}>
          <InlineTextInput
            type="phone"
            title="Phone*"
            inputRef={register({ required: true })}
            name="phone"
            errorMessage={errors.phone && 'Kolom Phone harus diisi'}
          />

          <InlineTextInput
            type="text"
            title="First Name*"
            inputRef={register({ required: true })}
            name="first_name"
            errorMessage={errors.first_name && 'Kolom First Name harus diisi'}
          />

          <InlineTextInput type="text" title="Last Name" inputRef={register()} name="last_name" />

          <div className="mt-5">
            <InlineTextArea
              title="Alamat*"
              placeholder="Nama Jalan, Blok Ruman, No. Apartemen, dll."
              inputRef={register({ required: true })}
              name="street_address"
              errorMessage={errors.street_address && 'Kolom Alamat harus diisi'}
            />
          </div>

          <InlineSelect
            title="Provinsi*"
            inputRef={register({ required: true })}
            name="province_id"
            options={provinces.map((province) => (
              <option key={province.province_id} value={province.province_id}>
                {province.province}
              </option>
            ))}
            errorMessage={errors.province_id && 'Kolom Province harus dipilih'}
            onChange={onChangeProvince}
          />

          <InlineSelect
            title="Kota*"
            inputRef={register({ required: true })}
            name="city_id"
            options={cities.map((city) => (
              <option key={city.city_id} value={city.city_id}>
                {city.city_name}
              </option>
            ))}
            errorMessage={errors.city_id && 'Kolom Kota harus dipilih'}
            onChange={onChangeCity}
          />

          <InlineSelect
            title="Kecamatan*"
            inputRef={register({ required: true })}
            name="district_id"
            options={districts.map((district) => (
              <option key={district.subdistrict_id} value={district.subdistrict_id}>
                {district.subdistrict_name}
              </option>
            ))}
            errorMessage={errors.district_id && 'Kolom Kecamatan harus dipilih'}
            onChange={onChangeDistrict}
          />

          <InlineTextInput
            type="number"
            title="Zip Code*"
            inputRef={register({ required: true })}
            name="zip_code"
            errorMessage={errors.zip_code && 'Kolom Zip Code harus diisi'}
            maxLength={5}
          />

          <div className="mt-5">
            <SubmitButton
              type="submit"
              text=" &emsp; Save &emsp; "
              textClass="text-white text-xs"
            />
          </div>
        </form>
      ) : (
        <div className="flex items-center rounded-xl border p-5">
          <div className="flex-1">
            <h4 className="font-bold">
              {`${checkout.first_name} ${checkout.last_name} (Ph: ${checkout.phone})`}
            </h4>
            <div>{checkout.street_address}</div>
            <div>{`${checkout.district}, ${checkout.city}`}</div>
            <div>{`${checkout.province} ${checkout.zip_code}`}</div>
          </div>
          <div
            className="px-4 py-6 text-blue-600 hover:text-blue-800 transition-all cursor-pointer"
            onClick={onClickEdit}>
            Edit
          </div>
        </div>
      )}
    </Collapsible>
  );
}
