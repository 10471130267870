import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { SubmitButton } from '../../components/Base';
import { applyVoucher } from '../../actions/checkout';

export default function VoucherForm() {
  const { register, errors, handleSubmit } = useForm();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch();

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    dispatch(applyVoucher(data))
      .then(() => setIsSubmittingForm(false))
      .catch(() => setIsSubmittingForm(false));
  };

  if (!authenticatedUser)
    return (
      <div className="p-4 w-full rounded-xl border border-gray-200">
        <div className="text-center">
          Silahkan login ke akun kamu untuk menggunakan voucher discount
        </div>
        <div className="mt-2 text-center">
          <Link
            to="/sign-in"
            className="mt-4 font-semibold text-blue-500 transition-all hover:underline">
            Login disini
          </Link>
        </div>
      </div>
    );
  return (
    <>
      <div className="p-4 w-full rounded-xl border border-gray-200">
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <input
            ref={register({ required: true })}
            type="text"
            className="w-full border rounded px-4 py-3"
            placeholder="Masukan kode voucher"
            name="voucher_code"
          />
          {errors.voucher_code && (
            <div className="mt-1 text-xs text-red-500">Kolom Kode Voucher harus diisi</div>
          )}

          <div className="mt-2">
            <SubmitButton
              type="submit"
              text="Gunakan Voucher"
              textClass="text-white text-xs"
              additionalClass="w-full"
              isLoading={isSubmittingForm}
            />
          </div>
        </form>
      </div>
    </>
  );
}
