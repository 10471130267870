import Swal from 'sweetalert2';
import {
  FETCH_USER_ADDRESS,
  SUBMIT_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  REFRESH_USER_ADDRESS_DATATABLES,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const fetchUserAddress = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/user-address`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_USER_ADDRESS, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_USER_ADDRESS, result: [] });
            Swal.fire('Fetch Address Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshUserAddressDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_USER_ADDRESS_DATATABLES });
  };
};

/**
 * @param data      address_name, first_name, last_name, phone, street_address,
 *                  district_id, district, city_id, city, province_id, province, zip_code, is_default
 */
export const submitUserAddress = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/user-address`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_USER_ADDRESS, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshUserAddressDatatables()))
      .then(() => dispatch(fetchUserAddress()));
  };
};

/**
 * @param id
 * @param data      address_name, first_name, last_name, phone, street_address,
 *                  district_id, district, city_id, city, province_id, province, zip_code, is_default
 */
export const updateUserAddress = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/user-address/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_USER_ADDRESS, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshUserAddressDatatables()))
      .then(() => dispatch(fetchUserAddress()));
  };
};

/**
 * @param id
 */
export const deleteUserAddress = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/user-address/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_USER_ADDRESS, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshUserAddressDatatables()))
      .then(() => dispatch(fetchUserAddress()));
  };
};
