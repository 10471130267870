import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import UserAddressForm from './UserAddressForm';
import UserInformationForm from './UserInformationForm';
import ShippingForm from './ShippingForm';
import PaymentForm from './PaymentForm';
import VoucherForm from './VoucherForm';
import CheckoutSummary from './CheckoutSummary';

import { addCartToCheckout } from '../../actions/checkout';

export default function Checkout() {
  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { result: carts } = useSelector((state) => state.cart);
  const checkout = useSelector((state) => state.checkout);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addCartToCheckout(carts));
  }, []);

  return (
    <>
      <h2 className="text-2xl font-bold">Checkout</h2>

      <section className="flex flex-wrap mt-5 pb-10">
        <div className="w-full lg:w-2/3">
          {authenticatedUser ? <UserAddressForm /> : <UserInformationForm />}
          {!checkout.isEditingUserInformation && (
            <>
              <ShippingForm />
              <PaymentForm />
            </>
          )}
        </div>
        <div className="w-full lg:w-1/3 lg:pl-8 mt-6 lg:mt-0">
          <VoucherForm />
          <CheckoutSummary />
        </div>
      </section>
    </>
  );
}
