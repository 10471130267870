import React, { Fragment, useState, useEffect } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  MenuIcon,
  XIcon,
  SearchIcon,
  // HeartIcon,
  ShoppingCartIcon,
} from '@heroicons/react/outline';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NavbarPopoverMenu from './NavbarPopoverMenu';

const navbarLogo = require('../assets/img/bstore.png');
const profilePicture = require('../assets/img/profile-picture.png');

export default function Navbar() {
  const PROFILE_MENUS = [
    { name: 'My Profile', route: '/profile', auth: true },
    { name: 'Order History', route: '/order-history', auth: true },
    { name: 'Wishlist', route: '/wishlist', auth: true },
    { name: 'Change Password', route: '/password', auth: true },
    { name: 'Sign Out', route: '/sign-out', auth: true },
    { name: 'Sign In', route: '/sign-in', auth: false },
    { name: 'Create Account', route: '/sign-up', auth: false },
  ];

  const location = useLocation();
  const history = useHistory();

  const [productCategorySlug, setProductCategorySlug] = useState('');
  const [search, setSearch] = useState('');

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { result: productCategories } = useSelector((state) => state.productCategory);
  const { result: carts } = useSelector((state) => state.cart);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const qCategory = query.get('category');
    const qSearch = query.get('s');
    setProductCategorySlug(qCategory ? qCategory : '');
    setSearch(qSearch ? qSearch : '');
  }, [location]);

  //

  const renderSearchBar = (closePanel = () => {}) => {
    const onSearch = () => {
      closePanel();
      onClickSearch();
    };
    return (
      <div className="flex flex-1 items-center p-1 bg-white rounded-xl h-12">
        <div className="px-3 w-full">
          <input
            className="text-sm outline-none w-full"
            type="text"
            placeholder="Cari Produk disini"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') onSearch();
            }}
            onBlur={onSearch}
            onSubmit={onSearch}
          />
        </div>
        <button className="bg-purple-800 mr-0.5 p-2 rounded-lg" onClick={onSearch}>
          <SearchIcon className="w-5 text-white" />
        </button>
      </div>
    );
  };

  const onClickSearch = () => {
    const newLocation = {
      pathname: '/product',
      search: `?s=${search}&category=${productCategorySlug}`,
    };
    history.push(newLocation);
  };

  return (
    <>
      <Disclosure as="nav" className="bg-purple-800">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto py-4 px-4">
              <div className="relative flex items-center justify-between">
                {/* LEFT SIDE NAVBAR */}
                <div className="w-1/3 md:w-1/4 flex items-center">
                  {/* WIDE SCREEN : Website Logo */}
                  <Link to="/" className="hidden md:block">
                    <img className="h-12 object-contain" src={navbarLogo} alt="BSTORE" />
                  </Link>

                  {/* MOBILE : Menu Button */}
                  <Disclosure.Button
                    className="flex md:hidden items-center justify-center p-2 rounded-md text-gray-200 hover:text-white
                    hover:bg-purple-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>

                {/* CENTER NAVBAR */}
                <div className="w-1/3 md:w-2/4 flex items-center">
                  {/* MOBILE : Website Logo */}
                  <div className="flex flex-1 md:hidden items-center justify-center">
                    <Link to="/">
                      <img className="h-8 object-contain" src={navbarLogo} alt="BSTORE" />
                    </Link>
                  </div>

                  {/* WIDE SCREEN : Product Menu & Search Bar */}
                  <div className="hidden md:flex md:flex-1 items-center relative">
                    <NavbarPopoverMenu />
                    <div className="ml-10 flex-1">{renderSearchBar()}</div>
                  </div>
                </div>

                {/* RIGHT SIDE NAVBAR */}
                <div className="w-1/3 md:w-1/4 flex items-center justify-end space-x-6">
                  <Link
                    to="/cart"
                    className="relative flex items-center p-1 bg-transparent text-gray-200 hover:text-white font-medium">
                    <ShoppingCartIcon className="w-5" />
                    <span className="hidden md:block">&nbsp;Cart</span>
                    {carts.length > 0 && (
                      <div
                        className="flex justify-center items-center w-5 h-5 absolute top-0 -right-3
                        rounded-full bg-red-500 text-xs">
                        <small>{carts.length}</small>
                      </div>
                    )}
                  </Link>

                  {/* Profile Dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                            <img className="h-8 w-8 rounded-full" src={profilePicture} alt="" />
                          </Menu.Button>
                        </div>

                        <Transition
                          show={open}
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items
                            static
                            className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {PROFILE_MENUS.filter(
                              (menu) => menu.auth === (authenticatedUser ? true : false),
                            ).map((menu) => (
                              <Menu.Item key={menu.name}>
                                {({ active }) => (
                                  <Link
                                    to={menu.route}
                                    className={`${
                                      active ? 'bg-gray-100' : ''
                                    } block px-4 py-2 text-sm text-gray-700`}>
                                    {menu.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>

              {/* Bottom Bar (Product Category) */}
              {/* <div className="hidden sm:flex space-x-10 border-t border-opacity-20 py-2">
                {featuredProductCategories.map((productCategory) => (
                  <Link
                    key={productCategory.id}
                    to={`/product?category=${productCategory.url_slug}`}
                    className="text-xs text-gray-200 hover:text-white font-medium">
                    {productCategory.name}
                  </Link>
                ))}
              </div> */}
            </div>

            {/* Mobile Menu */}
            <Disclosure.Panel className="md:hidden">
              {({ close }) => (
                <div className="px-2 pt-2 pb-3 space-y-1">
                  <div className="px-2 mb-2">{renderSearchBar(close)}</div>
                  {productCategories.map((productCategory) => (
                    <Link
                      key={productCategory.id}
                      to={`/product?category=${productCategory.url_slug}`}
                      onClick={close}
                      className="w-full text-gray-200 hover:bg-purple-900 hover:text-white block px-3 py-2 rounded-md text-base font-medium">
                      {productCategory.name}
                    </Link>
                  ))}
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
