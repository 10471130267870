import { API_HEADER, API_HEADER_MULTIPART, AUTHENTICATED_USER_STORAGE_KEY } from '../constants/env';

export function authApiHeader() {
  const authenticatedUser = JSON.parse(localStorage.getItem(AUTHENTICATED_USER_STORAGE_KEY));

  if (authenticatedUser && authenticatedUser.token) {
    return {
      ...API_HEADER,
      Authorization: `Bearer ${authenticatedUser.token}`,
    };
  } else {
    return API_HEADER;
  }
}

export function authApiHeaderMultipart() {
  const authenticatedUser = JSON.parse(localStorage.getItem(AUTHENTICATED_USER_STORAGE_KEY));

  if (authenticatedUser && authenticatedUser.token) {
    return {
      ...API_HEADER_MULTIPART,
      Authorization: `Bearer ${authenticatedUser.token}`,
    };
  } else {
    return API_HEADER_MULTIPART;
  }
}
