import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PaymentTable from './PaymentTable';
import ShippingTable from './ShippingTable';
import ProductTable from './ProductTable';
import Review from './Review';
import Utils from '../../../../helpers/utils';

import { fetchOrderDetail } from '../../../../actions/order';

export default function OrderHistoryDetail() {
  const location = useLocation();
  const { hash } = useParams();

  const isGuest = location.pathname.indexOf('/order/guest') >= 0;
  const [isFetchingData, setIsFetchingData] = useState(true);

  const { detailResult: order } = useSelector((state) => state.order);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrderDetail(hash)).then(() => setIsFetchingData(false));
  }, []);

  if (isFetchingData) {
    return (
      <section className="flex flex-wrap justify-center py-10">
        <svg
          className="animate-spin -ml-1 mr-3 h-10 w-10 text-purple-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </section>
    );
  }
  return (
    <>
      <section className={`w-full mx-auto ${isGuest ? 'px-0 md:px-48' : ''}`}>
        {isGuest && (
          <div className="mb-5 pt-10 px-20 text-xl text-gray-400 text-center">
            Segera lakukan Pembayaran dan simpan Link halaman ini untuk memantau status Pesanan Anda
          </div>
        )}
        <div className="w-full bg-white shadow-lg rounded-xl p-6">
          <div className="flex">
            <div className="flex-1 text-lg">
              <h2 className="font-bold">{`Order #${order.order_no}`}</h2>
              <div>
                <span className="font-bold">Order Time </span>
                <span>{order.order_time}</span>
              </div>
            </div>
            <div
              className="flex-1 text-right"
              dangerouslySetInnerHTML={{ __html: Utils.generateOrderStatus(order) }}></div>
          </div>

          {order.is_paid === 0 && (
            <div className="mt-5">
              <PaymentTable />
            </div>
          )}
          <div className="mt-5">
            <ShippingTable />
          </div>
          <div className="mt-5">
            <ProductTable />
          </div>
          {order.is_paid === 1 && (
            <div className="mt-5">
              <PaymentTable />
            </div>
          )}
          {order.is_paid === 1 && order.is_shipped === 1 && (
            <div className="mt-5">
              <Review />
            </div>
          )}
        </div>
      </section>
    </>
  );
}
