import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import {
  Modal,
  InlineTextInput,
  InlineSwitch,
  InlineSelect,
  BasicButton,
  SubmitButton,
} from '../../../../components/Base';
import Collapsible from '../../../../components/Collapsible';
import { fetchProvince, fetchCity, fetchDistrict } from '../../../../actions/rajaongkir';
import { submitUserAddress, updateUserAddress } from '../../../../actions/userAddress';

function UserAddressCreate(props, ref) {
  const { register, errors, handleSubmit, setValue } = useForm();

  const [isVisible, setIsVisible] = useState(false);
  const [initialUserAddress, setInitialUserAddress] = useState(null);
  const [provinceName, setProvinceName] = useState('');
  const [cityName, setCityName] = useState('');
  const [districtName, setDistrictName] = useState('');
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { provinces, cities, districts } = useSelector((state) => state.rajaongkir);
  const dispatch = useDispatch();

  useImperativeHandle(ref, () => ({
    showForm,
    showFormWithInitialData,
  }));

  useEffect(() => {
    dispatch(fetchProvince());
  }, []);

  useEffect(() => {
    if (initialUserAddress) {
      setTimeout(() => {
        setValue('address_name', initialUserAddress.address_name);
        setValue('is_default', initialUserAddress.is_default === 1);
        setValue('street_address', initialUserAddress.street_address);
        setValue('province_id', `${initialUserAddress.province_id}`);
        setProvinceName(initialUserAddress.province);
        setValue('zip_code', initialUserAddress.zip_code);
        setValue('first_name', initialUserAddress.first_name);
        setValue('last_name', initialUserAddress.last_name);
        setValue('phone', initialUserAddress.phone);

        dispatch(fetchCity(initialUserAddress.province_id))
          .then(() => dispatch(fetchDistrict(initialUserAddress.city_id)))
          .then(() => {
            setValue('city_id', `${initialUserAddress.city_id}`);
            setCityName(initialUserAddress.city);
            setValue('district_id', `${initialUserAddress.district_id}`);
            setDistrictName(initialUserAddress.district);
          });
      }, 100);
    }
  }, [initialUserAddress]);

  const showForm = () => setIsVisible(true);
  const closeForm = () => {
    setIsVisible(false);
    setInitialUserAddress(null);
    setProvinceName('');
    setCityName('');
    setDistrictName('');
    setIsSubmittingForm(false);
  };

  const showFormWithInitialData = (data) => {
    setInitialUserAddress(data);
    showForm();
  };

  const onChangeProvince = (e) => {
    const selectedProvince = provinces.find((p) => p.province_id === e.target.value);
    setProvinceName(selectedProvince.province);
    dispatch(fetchCity(selectedProvince.province_id));
  };

  const onChangeCity = (e) => {
    const selectedCity = cities.find((c) => c.city_id === e.target.value);
    setCityName(selectedCity.city_name);
    dispatch(fetchDistrict(selectedCity.city_id));
  };

  const onChangeDistrict = (e) => {
    const selectedDistrict = districts.find((d) => d.subdistrict_id === e.target.value);
    setDistrictName(selectedDistrict.subdistrict_name);
  };

  const onSubmitForm = (data) => {
    setIsSubmittingForm(true);

    data.province = provinceName;
    data.city = cityName;
    data.district = districtName;
    data.is_default = data.is_default ? 1 : 0;

    if (initialUserAddress) {
      dispatch(updateUserAddress(initialUserAddress.id, data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    } else {
      dispatch(submitUserAddress(data))
        .then(() => closeForm())
        .catch(() => setIsSubmittingForm(false));
    }
  };

  return (
    <Modal
      isVisible={isVisible}
      size="large"
      ModalContent={
        <div className="sm:flex sm:items-start">
          <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              {initialUserAddress ? 'Ubah Alamat' : 'Tambah Alamat Baru'}
            </Dialog.Title>
            <div className="mt-5">
              <form id="createForm" onSubmit={handleSubmit(onSubmitForm)}>
                <InlineTextInput
                  type="text"
                  title="Nama Alamat"
                  placeholder="Rumah, Kantor, Apartemen, dll."
                  inputRef={register({ required: true })}
                  name="address_name"
                  errorMessage={errors.address_name && 'Kolom Nama Alamat harus diisi'}
                />

                <InlineSwitch title="Alamat Default" inputRef={register()} name="is_default" />

                <Collapsible title="Alamat">
                  <InlineTextInput
                    type="text"
                    title="Alamat"
                    placeholder="Nama Jalan, Blok Ruman, No. Apartemen, dll."
                    inputRef={register({ required: true })}
                    name="street_address"
                    errorMessage={errors.street_address && 'Kolom Alamat harus diisi'}
                  />

                  <InlineSelect
                    title="Provinsi"
                    inputRef={register({ required: true })}
                    name="province_id"
                    options={provinces.map((province) => (
                      <option key={province.province_id} value={province.province_id}>
                        {province.province}
                      </option>
                    ))}
                    errorMessage={errors.province_id && 'Kolom Province harus dipilih'}
                    onChange={onChangeProvince}
                  />

                  <InlineSelect
                    title="Kota"
                    inputRef={register({ required: true })}
                    name="city_id"
                    options={cities.map((city) => (
                      <option key={city.city_id} value={city.city_id}>
                        {city.city_name}
                      </option>
                    ))}
                    errorMessage={errors.city_id && 'Kolom Kota harus dipilih'}
                    onChange={onChangeCity}
                  />

                  <InlineSelect
                    title="Kecamatan"
                    inputRef={register({ required: true })}
                    name="district_id"
                    options={districts.map((district) => (
                      <option key={district.subdistrict_id} value={district.subdistrict_id}>
                        {district.subdistrict_name}
                      </option>
                    ))}
                    errorMessage={errors.district_id && 'Kolom Kecamatan harus dipilih'}
                    onChange={onChangeDistrict}
                  />

                  <InlineTextInput
                    type="number"
                    title="Zip Code"
                    inputRef={register({ required: true })}
                    name="zip_code"
                    errorMessage={errors.zip_code && 'Kolom Zip Code harus diisi'}
                    maxLength={5}
                  />
                </Collapsible>

                <Collapsible title="PIC">
                  <InlineTextInput
                    type="text"
                    title="First Name"
                    inputRef={register({ required: true })}
                    name="first_name"
                    errorMessage={errors.first_name && 'Kolom First Name harus diisi'}
                    defaultValue={authenticatedUser.first_name}
                  />

                  <InlineTextInput
                    type="text"
                    title="Last Name"
                    inputRef={register()}
                    name="last_name"
                    defaultValue={authenticatedUser.last_name}
                  />

                  <InlineTextInput
                    type="text"
                    title="Phone"
                    inputRef={register({ required: true })}
                    name="phone"
                    errorMessage={errors.phone && 'Kolom Phone harus diisi'}
                    defaultValue={authenticatedUser.phone}
                  />
                </Collapsible>
              </form>
            </div>
          </div>
        </div>
      }
      ModalButton={
        <>
          <SubmitButton
            type="submit"
            form="createForm"
            text="Submit"
            textClass="text-white text-xs"
            isLoading={isSubmittingForm}
          />
          <BasicButton text="Cancel" textClass="text-white text-xs mr-2" onClick={closeForm} />
        </>
      }
    />
  );
}

export default forwardRef(UserAddressCreate);
