import React, { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, ChevronRightIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PRODUCT_CATEGORY_TYPES } from '../constants/data';

export default function NavbarPopoverMenu() {
  const [typeFilter, setTypeFilter] = useState('');

  const { result: productCategories } = useSelector((state) => state.productCategory);

  return (
    <Popover className="flex">
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? 'text-white' : 'text-gray-200'}
                group inline-flex items-center hover:text-white transition duration-150 ease-in-out`}>
            <MenuIcon
              className={`${open ? 'text-white' : 'text-gray-200'}
                h-6 w-6 mr-3 group-hover:text-white transition duration-150 ease-in-out`}
              aria-hidden="true"
            />
            <strong>Produk</strong>
          </Popover.Button>
          <Popover.Overlay className="fixed inset-0 top-20 bg-black opacity-50" />

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute inset-0 top-16 z-10 w-full">
              <div className="grid grid-cols-2 bg-white h-96 rounded-b-lg shadow-lg overflow-hidden">
                <div className="shadow-2xl py-4">
                  {Object.values(PRODUCT_CATEGORY_TYPES).map((type) => (
                    <button
                      key={type}
                      className={`${typeFilter === type ? 'bg-gray-100' : ''}
                        flex w-full items-center px-6 py-2 hover:bg-gray-100`}
                      onMouseOver={() => setTypeFilter(type)}>
                      <div className="flex-1 text-left text-sm">{type}</div>
                      <ChevronRightIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  ))}
                  <Link to="/product">
                    <button className="px-6 py-2" onClick={close}>
                      <span className="text-left text-sm text-purple-600 font-semibold">
                        Lihat Semua Produk
                      </span>
                    </button>
                  </Link>
                </div>
                <div className="py-4 overflow-y-scroll">
                  {productCategories
                    .filter((productCategory) => productCategory.type === typeFilter)
                    .map((productCategory) => (
                      <div key={productCategory.id} className="mb-2">
                        <Link to={`/product?category=${productCategory.url_slug}`}>
                          <button
                            className="flex w-full group items-center px-6 py-2 hover:underline underline-offset-4"
                            onClick={close}>
                            <div className="flex-1 text-left text-sm font-bold uppercase">
                              {productCategory.name}
                            </div>
                          </button>
                        </Link>
                        {productCategory.sub_categories.map((subCategory) => (
                          <Link
                            key={subCategory.id}
                            to={`/product?category=${subCategory.url_slug}`}>
                            <button
                              className="flex w-full group items-center px-6 py-2 hover:underline underline-offset-4"
                              onClick={close}>
                              <div className="flex-1 text-left text-sm uppercase">
                                {subCategory.name}
                              </div>
                            </button>
                          </Link>
                        ))}
                      </div>
                    ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
