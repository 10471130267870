import Swal from 'sweetalert2';
import {
  SUBMIT_CHANGE_PROFILE,
  // SUBMIT_CHANGE_PROFILE_PICTURE,
  SUBMIT_CHANGE_PASSWORD,
} from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';
import { setAuthenticatedUser } from './authenticatedUser';

/**
 * @param data      first_name, last_name, gender
 */
export const changeProfile = (data) => {
  return (dispatch, getState) => {
    return fetch(`${API_URL}/profile`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        const { result: authenticatedUser } = getState().authenticatedUser;

        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');

            dispatch(
              setAuthenticatedUser({
                ...authenticatedUser,
                ...response.data,
              }),
            );
            dispatch({ type: SUBMIT_CHANGE_PROFILE, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};

/**
 * @param data      current_password, new_password, confirm_new_password
 */
export const changePassword = (data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/change-password`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_CHANGE_PASSWORD, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};

// Params   data = { userToken, profilePicture }
/* export const changeProfilePicture = (data) => {
  return (dispatch) => {
    return fetch(`${url}/user/profile-picture`, {
      method: 'POST',
      body: data,
      headers: headerMultipart,
    })
      .then(response => response.json())
      .then((response) => {
        dispatch({ type: SUBMIT_CHANGE_PROFILE_PICTURE, result: response });
      })
      .catch((err) => {
        Alert.alert('Connection Error', err.toString());
      })
      .then(() => dispatch({ type: CLEAR_SUBMIT_RESULT }));
  };
}; */
