import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { submitSignOut } from '../../../actions/auth';

export default function SignOut() {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(submitSignOut()).then(() => history.push('/'));
  }, []);

  return null;
}
