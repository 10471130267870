import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const profilePicture = require('../../assets/img/profile-picture.png');

export default function ProfileSidebar() {
  const PROFILE_MENUS = [
    { name: 'My Profile', route: '/profile' },
    { name: 'Order History', route: '/order-history' },
    { name: 'Wishlist', route: '/wishlist' },
    { name: 'Alamat', route: '/user-address' },
    { name: 'Change Password', route: '/password' },
  ];
  const location = useLocation();

  const { result: authenticatedUser } = useSelector((state) => state.authenticatedUser);

  return (
    <>
      <div className="w-full shadow-lg rounded-xl p-6 lg:sticky lg:top-28">
        <div className="p-3 text-center">
          <img
            src={profilePicture}
            alt=""
            className="h-24 w-24 rounded-full object-cover mx-auto"
          />
          <h4 className="mt-3 text-gray-800 font-bold uppercase">
            {`${authenticatedUser?.first_name} ${authenticatedUser?.last_name}`}
          </h4>
          <div className="-mt-1 text-sm text-gray-500">{authenticatedUser?.phone}</div>
        </div>

        {/* Navigation */}
        <ul className="mt-3 flex flex-col md:min-w-full list-none">
          {PROFILE_MENUS.map((menu) => (
            <Link key={menu.name} to={menu.route}>
              <li
                className={`px-3 py-3 mb-1 rounded-md hover:bg-purple-600 transition-all
                    text-xs uppercase hover:text-white
                    ${
                      location.pathname === menu.route
                        ? 'bg-purple-600 text-white'
                        : 'text-gray-500'
                    }`}>
                {menu.name}
              </li>
            </Link>
          ))}

          <Link to="/sign-out" className="border-t pt-1">
            <li
              className="px-3 py-3 rounded-md hover:bg-purple-600 transition-all
              text-xs uppercase hover:text-white text-gray-500">
              Sign Out
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
}
