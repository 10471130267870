import {
  SUBMIT_CHANGE_PROFILE,
  SUBMIT_CHANGE_PROFILE_PICTURE,
  SUBMIT_CHANGE_PASSWORD,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  submitResult: {},
};

export default function profile(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_CHANGE_PROFILE:
    case SUBMIT_CHANGE_PROFILE_PICTURE:
    case SUBMIT_CHANGE_PASSWORD:
      return {
        ...state,
        submitResult: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
