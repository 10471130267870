import Swal from 'sweetalert2';
import { FETCH_REVIEW, SUBMIT_REVIEW, DELETE_REVIEW, REFRESH_REVIEW_DATATABLES } from './_types';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';
import { fetchOrderDetail } from './order';

export const fetchReview = () => {
  return (dispatch) => {
    return fetch(`${API_URL}/review`, {
      method: 'GET',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            dispatch({ type: FETCH_REVIEW, result: response.data });
            break;

          case API_RESPONSE.FAILED:
          default:
            dispatch({ type: FETCH_REVIEW, result: [] });
            Swal.fire('Fetch Review Failed', response[API_RESPONSE_MESSAGE], 'error');
        }
      });
  };
};

export const refreshReviewDatatables = () => {
  return (dispatch) => {
    dispatch({ type: REFRESH_REVIEW_DATATABLES });
  };
};

/**
 * @param data      product_id, order_id, order_detail_id, star, review
 */
export const submitReview = (data) => {
  return (dispatch, getState) => {
    return fetch(`${API_URL}/review`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_REVIEW, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshReviewDatatables()))
      .then(() => dispatch(fetchReview()))
      .then(() => {
        const { detailResult: order } = getState().order;
        if (order.hash) dispatch(fetchOrderDetail(order.hash));
      });
  };
};

/**
 * @param id
 * @param data      is_public
 */
export const updateReview = (id, data) => {
  return (dispatch) => {
    return fetch(`${API_URL}/review/${id}`, {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: SUBMIT_REVIEW, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshReviewDatatables()))
      .then(() => dispatch(fetchReview()));
  };
};

/**
 * @param id
 */
export const deleteReview = (id) => {
  return (dispatch) => {
    return fetch(`${API_URL}/review/${id}`, {
      method: 'DELETE',
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            Swal.fire('Delete Success', response[API_RESPONSE_MESSAGE], 'success');
            dispatch({ type: DELETE_REVIEW, result: response });
            break;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Delete Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      })
      .then(() => dispatch(refreshReviewDatatables()))
      .then(() => dispatch(fetchReview()));
  };
};
