import { FETCH_SETTINGS, RESET_STORE } from '../actions/_types';

const initialState = {
  result: null,
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        result: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
