import React from 'react';

export default function InlineTextArea({
  title,
  inputRef,
  errorMessage,
  inputWidth = 'w-full',
  ...props
}) {
  return (
    <>
      <div className="relative w-full mb-5">
        <div className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-1 md:gap-4 items-center">
          <div>
            <label className="block uppercase text-gray-700 text-xs font-bold">{title}</label>
          </div>

          <div className="col-span-2 xl:col-span-3">
            <div className={`relative flex flex-wrap items-stretch ${inputWidth}`}>
              <textarea
                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                ref={inputRef}
                placeholder={title}
                rows="4"
                {...props}></textarea>
            </div>

            {errorMessage && <span className="text-xs text-red-500">{errorMessage}</span>}
          </div>
        </div>
      </div>
    </>
  );
}
