import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import FeaturedProduct from '../Product/FeaturedProduct';
import ProductCard from '../Product/ProductCard';

import { fetchPaginatedProduct } from '../../actions/product';

export default function Home() {
  const { paginatedResult: paginatedProduct } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPaginatedProduct());
  }, []);

  return (
    <div>
      <div className="mt-10">
        <FeaturedProduct />
      </div>

      <div className="mt-14">
        <h2 className="text-2xl font-bold uppercase">Produk Terbaru dari BSTORE</h2>
        <div className="mt-8 grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-4 gap-y-6">
          {paginatedProduct.data &&
            paginatedProduct.data.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
        </div>

        <div className="mt-10 mb-20 text-center">
          <Link
            to="/product"
            className="font-semibold text-purple-600 hover:text-purple-800 hover:underline transition-all">
            Lihat Semua Produk
          </Link>
        </div>
      </div>
    </div>
  );
}
