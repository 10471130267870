import { FETCH_PROVINCE, FETCH_CITY, FETCH_DISTRICT, FETCH_SHIPPING_FEE } from '../actions/_types';

const initialState = {
  provinces: [],
  cities: [],
  districts: [],
  shippingFees: [],
};

export default function slider(state = initialState, action) {
  switch (action.type) {
    case FETCH_PROVINCE:
      return {
        ...state,
        provinces: action.result,
      };

    case FETCH_CITY:
      return {
        ...state,
        cities: action.result,
      };

    case FETCH_DISTRICT:
      return {
        ...state,
        districts: action.result,
      };

    case FETCH_SHIPPING_FEE:
      return {
        ...state,
        shippingFees: action.result,
      };

    default:
      return state;
  }
}
