import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ProfileSidebar from './ProfileSidebar';

import ProfileForm from './Form';
import OrderHistory from './OrderHistory';
import OrderHistoryDetail from './OrderHistory/Detail';
import Wishlist from './Wishlist';
import UserAddress from './UserAddress';
import Password from './Password';

export default function Profile() {
  return (
    <>
      <section className="flex flex-wrap mt-5 pb-10">
        <div className="relative w-full lg:w-1/3 lg:pr-10">
          <ProfileSidebar />
        </div>

        <div className="relative w-full lg:w-2/3 mt-6 lg:mt-0">
          <Switch>
            <Route path="/profile" exact component={ProfileForm} />
            <Route path="/order-history" exact component={OrderHistory} />
            <Route path="/order-history/:hash" exact component={OrderHistoryDetail} />
            <Route path="/wishlist" exact component={Wishlist} />
            <Route path="/user-address" exact component={UserAddress} />
            <Route path="/password" exact component={Password} />
          </Switch>
        </div>
      </section>
    </>
  );
}
