import {
  FETCH_PRODUCT_CATEGORY,
  FETCH_FEATURED_PRODUCT_CATEGORY,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  featuredResult: [],
};

export default function productCategory(state = initialState, action) {
  switch (action.type) {
    case FETCH_PRODUCT_CATEGORY:
      return {
        ...state,
        result: action.result,
      };

    case FETCH_FEATURED_PRODUCT_CATEGORY:
      return {
        ...state,
        featuredResult: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
