import Swal from 'sweetalert2';
import { API_URL, API_RESPONSE_STATUS, API_RESPONSE_MESSAGE, API_RESPONSE } from '../constants/env';
import { authApiHeader } from '../helpers/apiHeader';

export const requestIpay88Payment = (hash) => {
  return (dispatch) => {
    return fetch(`${API_URL}/ipay88/request/${hash}`, {
      method: 'POST',
      // body: JSON.stringify(data),
      headers: authApiHeader(),
    })
      .then((response) => response.json())
      .then((response) => {
        switch (response[API_RESPONSE_STATUS]) {
          case API_RESPONSE.SUCCESS:
            // Swal.fire('Submit Success', response[API_RESPONSE_MESSAGE], 'success');
            return response.data;

          case API_RESPONSE.FAILED:
          default:
            Swal.fire('Submit Failed', response[API_RESPONSE_MESSAGE], 'error');
            return Promise.reject(response[API_RESPONSE_MESSAGE]);
        }
      });
  };
};
