import {
  FETCH_ORDER,
  FETCH_ORDER_DETAIL,
  SUBMIT_ORDER,
  REFRESH_ORDER_DATATABLES,
  SUBMIT_ORDER_PAYMENT_CONFIRMATION,
  ACCEPT_REJECT_PAYMENT_CONFIRMATION,
  REFUND_ORDER,
  RESET_STORE,
} from '../actions/_types';

const initialState = {
  result: [],
  detailResult: {},
  submitResult: {},

  submitConfirmationResult: {},
  acceptConfirmationResult: {},

  refundResult: {},

  refresh: false,
};

export default function order(state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDER:
      return {
        ...state,
        result: action.result,
      };

    case FETCH_ORDER_DETAIL:
      return {
        ...state,
        detailResult: action.result,
      };

    case SUBMIT_ORDER:
      return {
        ...state,
        submitResult: action.result,
      };

    case REFRESH_ORDER_DATATABLES:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case SUBMIT_ORDER_PAYMENT_CONFIRMATION:
      return {
        ...state,
        submitConfirmationResult: action.result,
      };

    case ACCEPT_REJECT_PAYMENT_CONFIRMATION:
      return {
        ...state,
        acceptConfirmationResult: action.result,
      };

    case REFUND_ORDER:
      return {
        ...state,
        refundResult: action.result,
      };

    case RESET_STORE:
      return { ...initialState };

    default:
      return state;
  }
}
