import Swal from 'sweetalert2';
import moment from 'moment';
import { FETCH_CART, UPDATE_CART, EMPTY_CART } from './_types';
import { CART_STORAGE_KEY } from '../constants/env';

export const fetchCart = () => {
  return (dispatch) => {
    const cartsString = localStorage.getItem(CART_STORAGE_KEY);
    if (!cartsString) return dispatch({ type: FETCH_CART, result: [] });

    const carts = JSON.parse(cartsString);

    const time = moment().subtract(3, 'days').format('x');
    const filteredCarts = carts.filter((cart) => cart.time >= time);

    return dispatch({ type: FETCH_CART, result: [...filteredCarts] });
  };
};

/**
 * @param data      time, product, variant, quantity, onlineStock, storeStocks
 */
export const addToCart = (data) => {
  return (dispatch) => {
    const cartsString = localStorage.getItem(CART_STORAGE_KEY);
    const carts = cartsString ? JSON.parse(cartsString) : [];
    const cartIndex = carts.findIndex(
      (cart) =>
        cart.product &&
        cart.product.id === data.product.id &&
        (cart.variant === null || cart.variant.id === data.variant.id),
    );

    if (cartIndex >= 0) {
      carts[cartIndex] = {
        ...carts[cartIndex],
        ...data,
        quantity: carts[cartIndex].quantity + data.quantity,
      };
    } else {
      carts.push(data);
    }
    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(carts));

    Swal.fire('Submit Success', 'Product telah berhasil ditambahkan ke Cart', 'success');
    return dispatch({ type: UPDATE_CART, result: [...carts] });
  };
};

/**
 * @param index     cartIndex
 * @param data      time, product, variant, quantity
 */
export const updateCart = (index, data) => {
  return (dispatch) => {
    const cartsString = localStorage.getItem(CART_STORAGE_KEY);
    const carts = cartsString ? JSON.parse(cartsString) : [];
    carts[index] = {
      ...carts[index],
      ...data,
      quantity: carts[index].quantity + data.quantity,
    };
    if (carts[index].quantity <= 0) carts.splice(index, 1);

    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(carts));
    return dispatch({ type: UPDATE_CART, result: [...carts] });
  };
};

/**
 * @param index     index
 */
export const deleteCart = (index) => {
  return (dispatch) => {
    const cartsString = localStorage.getItem(CART_STORAGE_KEY);
    const carts = cartsString ? JSON.parse(cartsString) : [];
    carts.splice(index, 1);

    localStorage.setItem(CART_STORAGE_KEY, JSON.stringify(carts));
    return dispatch({ type: UPDATE_CART, result: [...carts] });
  };
};

export const emptyCart = () => {
  return (dispatch) => {
    localStorage.removeItem(CART_STORAGE_KEY);
    return dispatch({ type: EMPTY_CART, result: [] });
  };
};
