import React, { useRef, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Spinner } from '../../components/Base';
import { PAYMENT_GATEWAY_URL } from '../../constants/env';

import { requestIpay88Payment } from '../../actions/ipay88';

export default function Ipay88RedirectForm() {
  const { hash } = useParams();
  const history = useHistory();
  const paymentForm = useRef(null);

  const dispatch = useDispatch();

  const [checkoutId, setCheckoutId] = useState('');
  const [signature, setSignature] = useState('');

  // Request Checkout ID from Server
  useEffect(() => {
    dispatch(requestIpay88Payment(hash))
      .then((payment) => {
        setCheckoutId(payment.ipay88_checkout_id);
        setSignature(payment.ipay88_signature);
      })
      .catch(() => history.goBack());
  }, []);

  useEffect(() => {
    if (checkoutId.trim() !== '' && signature.trim() !== '') paymentForm.current.submit();
  }, [checkoutId, signature]);

  return (
    <>
      <div className="flex justify-center">
        <div className="mt-10 mb-60 max-w-md w-full">
          <p className="text-center text-lg">
            Mohon tunggu sebentar, Anda akan segera dialihkan ke halaman Pembayaran
          </p>
          <div className="mt-10 flex justify-center">
            <Spinner />
          </div>
        </div>

        <form ref={paymentForm} method="POST" action={`${PAYMENT_GATEWAY_URL}`}>
          <input type="hidden" name="CheckoutID" value={checkoutId} />
          <input type="hidden" name="Signature" value={signature} />
        </form>
      </div>
    </>
  );
}
