import React from 'react';
import { Link } from 'react-router-dom';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { APP_URL } from '../../constants/env';
import Utils from '../../helpers/utils';
import { DeleteButton } from '../../components/Base';

import { updateCart, deleteCart } from '../../actions/cart';

function CartCard({ cart, index }) {
  const { product, quantity = 0, onlineStock = 1 } = cart;
  const { original_price: originalPrice = 0, price = 0 } = product || null;

  const discount = Number(originalPrice) - Number(price);
  const discountPercentage =
    Number(originalPrice) > 0 ? (discount / Number(originalPrice)) * 100 : 0;

  const dispatch = useDispatch();

  //

  const onClickDelete = () => {
    dispatch(deleteCart(index));
  };

  const onClickMinusQuantity = () => {
    if (quantity <= 1) return;

    const updateData = {
      ...cart,
      time: moment().format('x'),
      quantity: -1,
    };
    dispatch(updateCart(index, updateData));
  };

  const onClickPlusQuantity = () => {
    if (quantity >= onlineStock) return;

    const updateData = {
      ...cart,
      time: moment().format('x'),
      quantity: 1,
    };
    dispatch(updateCart(index, updateData));
  };

  //

  return (
    <div className="p-4 w-full md:flex rounded-xl border border-gray-200">
      <div className="w-full md:w-1/2 lg:w-7/12 flex">
        <img
          className="object-contain object-center w-24 h-24 rounded"
          src={`${APP_URL}/${product.image_1}`}
          alt={product.name}
        />
        <div className="flex-1 px-3">
          <Link to={`/product/${product.url_slug}`}>
            <h2 className="font-bold leading-tight transition-all hover:text-blue-500 hover:underline">
              {product.name}
            </h2>
          </Link>
          {cart.variant && (
            <p className="text-gray-400 text-sm">{`Variasi: ${cart.variant.name}`}</p>
          )}

          <div className="mt-1">
            {discountPercentage > 0 && (
              <div className="text-sm">
                <span className="text-gray-300 line-through">
                  Rp {Utils.numberFormat(product.original_price)}
                </span>
                <span className="text-red-400 font-bold">
                  {` ${Utils.numberFormat(discountPercentage)}%`}
                </span>
              </div>
            )}
            <div className="font-semibold">Rp {Utils.numberFormat(product.price, 0)}</div>
          </div>
        </div>
      </div>
      <div className="mt-4 md:mt-0 w-full md:w-1/2 lg:w-5/12 flex">
        <div className="flex h-10">
          <button
            className="px-3 border border-r-0 rounded-l transition-all
              bg-white hover:bg-gray-100"
            onClick={onClickMinusQuantity}>
            <MinusIcon className="h-3 w-3" />
          </button>
          <input
            type="number"
            className="border pl-3 pr-0 py-2 w-16 text-center"
            value={quantity}
            readOnly
          />
          <button
            className="px-3 border border-l-0 rounded-r transition-all
              bg-white hover:bg-gray-100"
            onClick={onClickPlusQuantity}>
            <PlusIcon className="h-3 w-3" />
          </button>
        </div>
        <div className="ml-auto flex flex-col justify-between">
          <div className="mb-4 font-bold text-right">
            Rp {Utils.numberFormat(product.price * quantity, 0)}
          </div>
          <DeleteButton
            text="Hapus dari Cart"
            paddingClass="py-2 px-3"
            textClass="text-white text-xs"
            onClick={onClickDelete}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(CartCard);
