import React, { useState } from 'react';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import { StarIcon as StarIconOutline } from '@heroicons/react/outline';
import Utils from '../../helpers/utils';

export default function RatingStar({
  inputRef,
  defaultValue = 5,
  editable = true,
  size = 6,
  // onChange = () => {},
  ...props
}) {
  const [value, setValue] = useState(defaultValue);

  const onClickStar = (star) => {
    if (editable) setValue(star);
  };

  return (
    <>
      <div className="relative">
        <div className="flex items-center">
          {[1, 2, 3, 4, 5]
            .filter((star) => star <= value)
            .map((star) => (
              <button key={star} type="button" onClick={() => onClickStar(star)}>
                <StarIconSolid className={`w-${size} h-${size} text-yellow-400`} />
              </button>
            ))}
          {[1, 2, 3, 4, 5]
            .filter((star) => star > value)
            .map((star) => (
              <button key={star} type="button" onClick={() => onClickStar(star)}>
                <StarIconOutline className={`w-${size} h-${size} text-yellow-400`} />
              </button>
            ))}

          <div className="ml-2 my-1 pl-2 border-l border-gray-300">
            {Utils.numberFormat(value, 1)}
          </div>
        </div>

        <input ref={inputRef} type="hidden" value={value} {...props} />
      </div>
    </>
  );
}
