export const PRODUCT_CATEGORY_TYPES = {
  PHONE: 'Handphone & Tablet',
  LAPTOP: 'Komputer & Laptop',
  ACCESSORIES: 'Accessories',
};

export const USER_GENDER = {
  Male: { label: 'Laki-Laki', value: 'Male' },
  Female: { label: 'Perempuan', value: 'Female' },
};

export const SHIPPING_TYPES = {
  DELIVERY: 'Delivery',
  PICKUP: 'Self Pickup',
};
