import React from 'react';
import { useSelector } from 'react-redux';

import ReviewForm from './ReviewForm';
import Collapsible from '../../../../components/Collapsible';
import RatingStar from '../../../../components/RatingStar';

export default function Review() {
  const { detailResult: order } = useSelector((state) => state.order);

  return (
    <Collapsible title="Review">
      {order.order_details.map((detail, index) => (
        <div key={detail.id} className={index === 0 ? 'mt-2' : 'mt-10'}>
          <h2 className="leading-none text-lg font-bold">{detail.product_name}</h2>
          {detail.product_variant_name && (
            <p className="text-gray-400 text-sm">{`Variasi: ${detail.product_variant_name}`}</p>
          )}

          {detail.review ? (
            <div className="mt-3">
              <RatingStar editable={false} defaultValue={detail.review.star} />
              <p>{detail.review.review}</p>
            </div>
          ) : (
            <ReviewForm orderDetail={detail} />
          )}
        </div>
      ))}
    </Collapsible>
  );
}
